<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import FhMultiRangeSlider from "@/components/Form/FhMultiRangeSlider/FhMultiRangeSlider.vue";
import FhButton from "@/components/FhButton.vue";
import { useFacetStore } from "@/stores/FacetStore";

const props = defineProps({
  facet: {
    type: Object,
    default: () => ({})
  },
  currentFacetSelectionValues: {
    type: Array,
    default: () => []
  }
});
const facetsInStore = useFacetStore();
const state = ref(props.facet?.state);
const selectCurrentFacetInStore = computed(() => {
  return facetsInStore.facets.find((facet) => facet.facetId === state.value.facetId);
});
const controller = computed(() => props.facet);
const isLoading = computed(() => state.value?.isLoading);
const hasSelectedOptions = ref(state.value.values.some((item) => item.state === "selected"));
const isSeatingCapacityFacet = computed(() => state.value?.facetId === "seatingcapacity");
let unsubscribe = null;

const isPriceFacet = computed(() => state.value?.facetId === "price_dict");

const rangeType = computed(() => {
  if (isPriceFacet.value) return "usd";
  if (isSeatingCapacityFacet.value) return null;
  return "Inches";
});
const min = computed(() => state.value?.domain?.min);
const max = computed(() => state.value?.domain?.max);
const rangeValues = ref([min.value, max.value]);
const error = ref({
  value: false,
  message: ""
});

// Add a flag to track user interactions to prevent UI jumping when sliding
const userInteracting = ref(false);

const handleSliderMoveEnd = (values) => {
  const [start, end] = values;

  userInteracting.value = true;

  if (start >= min.value && end <= max.value && !isLoading.value) {
    error.value.value = false;
    controller.value.setRanges([
      {
        start,
        end,
        endInclusive: true,
        state: "selected"
      }
    ]);

    rangeValues.value = [start, end];
  }

  // Reset the interaction flag after a short delay
  setTimeout(() => {
    userInteracting.value = false;
  }, 300);
};

// ensure proper reset
const clearSelectedOptions = () => {
  controller.value.deselectAll();

  const currentMin = selectCurrentFacetInStore.value?.domain?.min || state.value?.domain?.min;
  const currentMax = selectCurrentFacetInStore.value?.domain?.max || state.value?.domain?.max;

  // Reset range values to min/max
  rangeValues.value = [currentMin, currentMax];

  hasSelectedOptions.value = false;
};

// Add an additional watcher for selected values to ensure slider updates when cleared externally
watch(
  () => state.value?.values.filter((item) => item.state === "selected").length,
  (newLength) => {
    if (newLength === 0) {
      // If no selected values, reset slider to min/max
      rangeValues.value = [min.value, max.value];
      hasSelectedOptions.value = false;
    }
  }
);

const updateRangeValuesFromState = () => {
  if (userInteracting.value) return;

  // Check if there are selected values in the state
  const selectedValues = state.value.values.filter((item) => item.state === "selected");
  if (selectedValues.length > 0) {
    const selectedRange = selectedValues[0];
    if (selectedRange.start !== undefined && selectedRange.end !== undefined) {
      rangeValues.value = [selectedRange.start, selectedRange.end];
    }
  }
};

onMounted(() => {
  // Set up the subscription for future state changes
  unsubscribe = controller.value.subscribe(() => {
    state.value = controller.value.state;
    updateRangeValuesFromState();
  });

  // we're handling both initial state and future changes
  state.value = controller.value.state;
  updateRangeValuesFromState();
});

onUnmounted(() => {
  unsubscribe();
});

watch(
  () => state.value,
  (newVal) => {
    // Skip UI updates during user interaction
    if (userInteracting.value) return;

    hasSelectedOptions.value = newVal.values.filter((item) => item.state === "selected").length;

    // When state changes, also update the range values if there are selected options
    updateRangeValuesFromState();
  }
);
watch(
  () => [min.value, max.value],
  (newMinMax) => {
    // Skip updates during user interaction
    if (userInteracting.value) return;
    rangeValues.value = [newMinMax[0], newMinMax[1]];
  }
);
</script>

<template>
  <FhMultiRangeSlider
    v-model="rangeValues"
    class="mb-f1"
    :selected-options="rangeValues"
    :min="min"
    :max="max"
    :range-type="rangeType"
    :disabled="isLoading"
    step="1"
    @update:slider-move-end="handleSliderMoveEnd"
  />
  <FhButton v-if="hasSelectedOptions > 0" variant="text" class="mt-f1" @click="clearSelectedOptions">Clear</FhButton>
</template>

<style lang="pcss" scoped></style>
