<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useViewportSizes } from "@/composables";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";
import RegularFacet from "@/components/FhCoveo/FacetType/RegularFacet.vue";
import NumericFacet from "@/components/FhCoveo/FacetType/NumericFacet.vue";
import DateFacet from "@/components/FhCoveo/FacetType/DateFacet.vue";
import CategoryFacet from "@/components/FhCoveo/FacetType/CategoryFacet.vue";
import FhCoveoProductFilterNav from "@/components/FhCoveoProductFilterNav.vue";
import FhCoveoProductFilterChips from "@/components/FhCoveoProductFilterChips.vue";
import IconAnimatedUpDownChevron from "@/components/icons/IconAnimatedUpDownChevron.vue";
import FhSortFilter from "@/components/Partials/FhSortFilter/FhSortFilter.vue";
import { markSortAsInteractedWith } from "@/coveo/controllers";

// Props
const props = defineProps({
  facets: {
    type: Object,
    default: () => ({})
  },
  totalAmount: {
    type: Number,
    default: 0
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  sortController: {
    type: Object,
    default: () => ({})
  }
});
const emit = defineEmits(["update:allFiltersCount", "clearAll"]);

const facetState = computed(() => props.facets);
const hasFacets = computed(() => Object.keys(facetState.value).length > 0);
const openAllFilters = ref(false);
const { isSm, isMd } = useViewportSizes();
const hoveredIndex = ref({});
const amountEntries = computed(() => props.totalAmount || 0);
const isLoading = computed(() => props.isLoading);

const sortState = ref(props.sortController.state);
const appliedSort = ref(props.sortController.state.appliedSort);
const sortOptions = computed(() => {
  const mappedSortValues = [];
  sortState.value.availableSorts.forEach((value) => {
    if (value.by === "fields") {
      value.fields.forEach((val) => {
        mappedSortValues.push({
          key: val.displayName,
          direction: val.direction,
          name: val.displayName,
          coveoName: val.name
        });
      });
    } else {
      mappedSortValues.push({
        key: value.by,
        name: value.by === "relevance" ? "Recommended" : value.by,
        coveoName: value.by,
        direction: null
      });
    }
  });
  return mappedSortValues;
});

// Function to determine the current sort model from applied sort
const getCurrentSortFromAppliedSort = () => {
  const current = appliedSort.value;

  // Handle relevance sort
  if (current.by === "relevance") {
    return "relevance";
  }

  // Handle field-based sorting
  if (current.by === "fields" && current.fields && current.fields.length > 0) {
    // Find matching sort option
    const field = current.fields[0];
    const option = sortOptions.value.find((opt) => opt.coveoName === field.name && opt.direction === field.direction);
    return option ? option.key : null;
  }

  return null;
};

const sortModel = ref(getCurrentSortFromAppliedSort() || "relevance");
const previousSortModel = ref(sortModel.value);
const handleHoverEnter = (id) => {
  hoveredIndex.value[id] = true;
};

const handleHoverLeave = (id) => {
  hoveredIndex.value[id] = false;
};

const handleOpenAllFilters = (event) => {
  if (event) event.preventDefault();
  openAllFilters.value = !openAllFilters.value;
};

const getFacetComponent = (facet) => {
  switch (facet.type) {
    case "regular":
      return RegularFacet;
    case "numericalRange":
      return facet.state.facetId === "ec_effective_price" ? RegularFacet : NumericFacet;
    case "dateRange":
      return DateFacet;
    case "hierarchical":
      return CategoryFacet;
    default:
      return null;
  }
};
const currentSelectedFacetValues = ref([]);
const amountSelectedFacets = computed(() => currentSelectedFacetValues.value?.length || 0);

const updatetotalSelectedFilterOptions = () => {
  currentSelectedFacetValues.value = [];
  facetState.value.forEach((facet) => {
    if (facet.state.values && facet.state.type !== "hierarchical") {
      // regular, numericalRange
      facet.state.values.forEach((value) => {
        if (facet.isValueSelected(value)) {
          currentSelectedFacetValues.value.push({ value: value, facet: facet });
        }
      });
    } else if (facet.state.type === "hierarchical" && facet.state.activeValue) {
      // Category - hierarchical
      currentSelectedFacetValues.value.push({
        value: {
          value: facet.state.activeValue.value,
          state: facet.state.activeValue.state,
          numberOfResults: facet.state.activeValue.numberOfResults
        },
        facet: facet
      });
    }
  });

  // console.log("currentSelectedFacetValues:", currentSelectedFacetValues.value);
};

const clearAll = () => {
  // Manually deselect all facets before emitting clearAll
  facetState.value.forEach((facet) => {
    facet.deselectAll();
  });

  // Then emit clearAll for any parent components that need to respond
  emit("clearAll");

  // Update selected facet values after clearing
  updatetotalSelectedFilterOptions();
};

const sortBy = (sortKey) => {
  // Mark sort as interacted with when user changes sort
  markSortAsInteractedWith();
  const [sortOptionResult] = getSortOption(sortKey);
  props.sortController.sortBy(sortOptionResult);
};

const getSortOption = (sortKey) => {
  let sortKeyValue = sortKey;
  if (!sortKeyValue) {
    sortKeyValue = previousSortModel.value;
    sortModel.value = previousSortModel.value;
  }

  if (sortKeyValue === "relevance") {
    return [{ by: "relevance" }];
  }
  const sortOption = sortOptions.value.find((option) => option.key === sortKeyValue);

  return [
    {
      by: "fields",
      criterion: "fields",
      fields: [
        {
          name: sortOption.coveoName,
          direction: sortOption.direction
        }
      ]
    }
  ];
};

watch(
  () => sortModel.value,
  (newVal) => {
    previousSortModel.value = newVal;
  }
);

onMounted(() => {
  props.sortController.subscribe(() => {
    sortState.value = props.sortController.state;
    appliedSort.value = props.sortController.state.appliedSort;

    // Update sort model when sort changes from outside this component
    const newSortModel = getCurrentSortFromAppliedSort();
    if (newSortModel && newSortModel !== sortModel.value) {
      sortModel.value = newSortModel;
      previousSortModel.value = newSortModel;
    }
  });

  // Initialize with current sort on mount
  const initialSort = getCurrentSortFromAppliedSort();
  if (initialSort) {
    sortModel.value = initialSort;
    previousSortModel.value = initialSort;
  }
});
</script>

<template>
  <div v-if="hasFacets" :class="amountSelectedFacets > 0 ? 'pb-f5' : ''" class="flex flex-col flex-wrap justify-start items-start bg-white w-full">
    <div class="facet-container flex flex-wrap justify-between bg-white py-f5 w-full items-center">
      <div class="">
        <template v-for="(facetItem, index) in facetState" :key="`facet-${facetItem.state.facetId}-${index}`">
          <div
            v-if="index < 5"
            :id="`facet-${facetItem.state.facetId}-${index}`"
            class="relative mr-f3 hidden lg:inline-block"
            :class="{ 'hover-class': hoveredIndex[`facet-${facetItem.state.facetId}-${index}`] || false }"
            @mouseover="handleHoverEnter(`facet-${facetItem.state.facetId}-${index}`)"
            @mouseleave="handleHoverLeave(`facet-${facetItem.state.facetId}-${index}`)"
          >
            <FhButton variant="pill" size="medium" :color="facetItem.state.hasActiveValues ? 'selected' : 'secondary'" :disabled="false">
              <span>{{ facetItem.state.displayName }}</span>
              <IconAnimatedUpDownChevron
                :id="`facet-icon-${facetItem.state.facetId}-${index}`"
                class="w-5 h-5"
                :trigger-animate="hoveredIndex[`facet-${facetItem.state.facetId}-${index}`]"
                start-position="down"
              />
            </FhButton>

            <!-- Facet Values Dropdown -->
            <div class="hoverMenuPanel">
              <component :is="getFacetComponent(facetItem)" :facet="facetItem" @update:selected-facet-amount="updatetotalSelectedFilterOptions" />
            </div>
          </div>
        </template>

        <FhButton
          v-if="true"
          class="allFilter__cta align-middle"
          variant="pill"
          :color="isSm || isMd ? 'primary-inverse' : 'primary'"
          @click="handleOpenAllFilters"
        >
          <FhIcon name="List" />
          <span class="hidden sort-bp:block">All Filters</span>
          <span class="sort-bp:hidden pl-0">Sort & Filter</span>
          <span v-if="amountSelectedFacets > 0">({{ amountSelectedFacets }})</span>
        </FhButton>
      </div>

      <div class="flex flex-row gap-7">
        <FhSortFilter
          v-if="sortOptions.length > 0"
          v-model="sortModel"
          :sort-options="sortOptions"
          class="hidden sort-bp:block align-middle items-center mr-f2 text-f-sm-lg font-normal"
          @update:model-value="sortBy"
        />
        {{ amountEntries }} items
      </div>
    </div>
    <FhCoveoProductFilterChips
      :is-loading="isLoading"
      :current-selected-facet-values="currentSelectedFacetValues"
      :total-active-filter-option-count="amountSelectedFacets"
      class="items-center hidden lg:flex"
    >
      <li v-if="amountSelectedFacets > 0">
        <FhButton variant="text" size="small" class="hidden lg:block clearAll-btn" @click="clearAll">Clear All</FhButton>
      </li>
    </FhCoveoProductFilterChips>
  </div>

  <FhCoveoProductFilterNav
    :show-filter-nav="openAllFilters"
    :filter-model="facetState"
    :total-active-filter-option-count="amountSelectedFacets > 0 ? amountEntries : 0"
    :current-facet-selection-values="currentSelectedFacetValues"
    :facet-counts="props.facetCounts"
    :total-products-count="props.totalProductsCount"
    @update:show-filter="handleOpenAllFilters"
    @clear-all="clearAll"
  >
    <template #chips>
      <FhCoveoProductFilterChips
        v-if="amountSelectedFacets > 0"
        :current-selected-facet-values="currentSelectedFacetValues"
        :total-active-filter-option-count="amountSelectedFacets"
        class="panel-filter-list overflow-x-auto border-b border-b-neutral-30 py-5 px-3.5 md:px-10"
      />
    </template>
    <template #topPanel>
      <FhSortFilter
        v-if="sortOptions.length > 0"
        v-model="sortModel"
        :sort-options="sortOptions"
        :is-menu-open="true"
        :keep-menu-open="true"
        class="sort-bp:flex py-f2 flex-col w-full border-b border-b-neutral-30"
        @update:model-value="sortBy"
      />
    </template>
  </FhCoveoProductFilterNav>
</template>

<style scoped lang="pcss">
.hoverMenuPanel {
  width: max-content;
  @apply mt-f3 absolute pl-f2 z-10 min-w-[328px] bg-white px-f2.5 pt-f1 pb-f2 overflow-hidden drop-shadow;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
}
.hover-class .hoverMenuPanel {
  @apply block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

/* Gap between button and panel */
.hover-class:after {
  content: "";
  position: absolute;
  width: 100%;
  display: block;
  background: transparent;
  height: 60px;
  z-index: 0;
}

:deep(.btn.allFilter__cta) {
  @media (max-width: 1199px) {
    @apply pl-0;
  }
}

.facet-container :deep(.sortFilter__filters) {
  z-index: 10;
  @apply bg-white shadow-md p-4 w-64 mt-3;
}

.facet-container :deep(.sortFilter__filters ul) {
  @apply w-full;
}
.facet-container :deep(.sortFilter__filters li) {
  @apply my-2;
}
.facet-container :deep(.sortFilter .sortFilter__filter) {
  @apply text-f-sm-lg;
}

.facet-container :deep(.sortFilter__filters) {
  position: absolute;
  @apply mt-f3;
}
.facet-container :deep(.sortFilter > div) {
  display: flex;
  @apply bg-white items-center;
}

.facet-container :deep(.sortFilter ul) {
  width: max-content;
}

.FacetValues :deep(.c-checkbox) {
  @apply text-f-base-lg;
}
</style>
