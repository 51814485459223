<script setup>
import { ref, reactive, computed, nextTick, watch, provide } from "vue";
import { useViewportSizes } from "@/composables";
import FhIcon from "@/components/FhIcon.vue";
import FhNavCart from "@/components/FhNavCart.vue";
import FhNav from "@/components/FhNav.vue";
import FhSiteSearch from "@/components/SiteHeader/FhSiteSearch/FhSiteSearch.vue";
import Logo from "@/assets/four-hands-wordmark.svg"; // TODO: how to inline SVGs?
import { ACCOUNT } from "@/constants/routes";
import UpRightArrow from "./icons/IconUpRightArrow.vue";

const props = defineProps({
  isAuthenticated: {
    type: Boolean
  },
  canAddToCart: {
    type: Boolean
  },
  mainMenu: {
    type: Object,
    require: true,
    default: () => {}
  },
  accountMenu: {
    type: Object,
    require: true,
    default: () => {}
  },
  moreMenu: {
    type: Object,
    require: true,
    default: () => {}
  },
  isAdmin: {
    type: Boolean,
    default: null
  },
  isCustomerGuest: {
    type: Boolean,
    default: null
  },
  shouldHidePrice: {
    type: Boolean,
    default: false
  }
});
provide("shouldHidePrice", props.shouldHidePrice);
let { isSm, isMd, isLg } = useViewportSizes();
let superCategoryHoverTimer;

const mainMenuIsOpen = ref(false);
const mainMenuActiveSection = ref(null);
const mainMenuActiveSuperCategory = ref(null);
const mainMenuActiveCategory = ref(null);
const isNavOpen = ref(false);
const isAccountMenu = ref(false);
const showSearchInput = ref(false);
const searchInput = ref(null);
const mobileMenuRef = ref(null);
const showAllLink = ref(true);

const isAdmin = computed(() => props.isAdmin);
const isCustomerGuest = computed(() => props.isCustomerGuest);

watch(mobileMenuRef, async () => {
  if (mobileMenuRef.value && showSearchInput.value) {
    showSearchInput.value = false;
    searchInput.value = null;
  }
});

const mainMenuDropdownHasSupercategories = computed(() => {
  return mainMenuActiveSection.value !== null && mainMenuActiveSection.value.hasSuperCategories;
});

const mainNavItems = computed(() => {
  if (props.isAuthenticated) {
    return [
      { ...props.mainMenu.products, hasSuperCategories: true, key: "products" },
      { ...props.mainMenu.rooms, hasSuperCategories: false, key: "rooms" },
      { ...props.mainMenu.discover, hasSuperCategories: false, key: "discover" },
      { ...props.mainMenu.explore, hasSuperCategories: false, key: "explore" },
      { ...props.mainMenu.locations, hasSuperCategories: false, key: "locations" },
      { ...props.mainMenu.artStudio, hasSuperCategories: false, key: "artStudio" }
    ];
  }

  return [{ ...props.mainMenu.explore, hasSuperCategories: false, key: "explore" }];
});

const mobileNavItems = computed(() => {
  return {
    mainMenu: props.mainMenu,
    moreMenu: props.moreMenu,
    accountMenu: props.accountMenu
  };
});

const MENU_HOVER_SHOW_DELAY = 200;
const MENU_HOVER_HIDE_DELAY = 300;
const MENU_ITEM_HOVER_DELAY = 350;
let mainMenuMouseEnterTimer, mainMenuMouseLeaveTimer;

const marketingCTAs = reactive({ primaryCTA: ref({}), secondaryCTA: ref({}) });

function setCtaProperties(item) {
  marketingCTAs.primaryCTA = item.primaryCTA || null;
  marketingCTAs.secondaryCTA = item.secondCTA || null;
}

const DIAGONAL_TIMEOUT = 300; // milliseconds

// Add a ref to track if the mouse is over the search input
const isMouseOverSearch = ref(false);

// Modify handleMainNavItemMouseEnter
function handleMainNavItemMouseEnter(item) {
  if (showSearchInput.value && isMouseOverSearch.value) {
    // If mouse is over search, delay the menu transition
    setTimeout(() => {
      showSearchInput.value = false;
      mainMenuIsOpen.value = true;
      activateMainNavItem(item);
    }, DIAGONAL_TIMEOUT);
    return;
  }

  clearTimeout(mainMenuMouseLeaveTimer);
  mainMenuMouseEnterTimer = setTimeout(() => {
    if (showSearchInput.value) showSearchInput.value = false;
    mainMenuIsOpen.value = true;
    activateMainNavItem(item);
  }, MENU_ITEM_HOVER_DELAY);
}

function handleMainNavItemMouseLeave() {
  clearTimeout(mainMenuMouseEnterTimer);
}

function handleMainMenuMouseEnter() {
  clearTimeout(mainMenuMouseLeaveTimer);
}

function handleMainMenuMouseLeave() {
  clearTimeout(mainMenuMouseEnterTimer);
  mainMenuMouseLeaveTimer = setTimeout(() => closeMainMenu(), MENU_HOVER_HIDE_DELAY);
}

function activateMainNavItem(item) {
  mainMenuActiveSection.value = item;
  if (mainMenuDropdownHasSupercategories.value) {
    activateSuperCategory(item.childItems[0]);
  } else {
    activateSuperCategory(item);
  }
}

function activateSuperCategory(item) {
  setCtaProperties(item);
  mainMenuActiveSuperCategory.value = item;
  if (!mainMenuDropdownHasSupercategories.value && item?.childItems) {
    activateCategory(item.childItems[0]);
  } else {
    mainMenuActiveCategory.value = null;
  }
}

function activateCategory(item) {
  if (mainMenuActiveSection.value.key === "discover" || mainMenuActiveSection.value.key === "explore") {
    setCtaProperties(item);
    showAllLink.value = false;
  } else if (mainMenuDropdownHasSupercategories.value) {
    setCtaProperties(mainMenuActiveSuperCategory.value);
    showAllLink.value = true;
  } else {
    setCtaProperties(item);
    showAllLink.value = true;
  }
  mainMenuActiveCategory.value = item;
}

function isMainMenuItemActive(item) {
  return mainMenuActiveSection.value !== null && mainMenuActiveSection.value.name === item.name;
}

function isSuperCategoryActive(item) {
  return mainMenuActiveSuperCategory.value !== null && mainMenuActiveSuperCategory.value.name == item.name;
}

function isCategoryActive(item) {
  return mainMenuActiveCategory.value != null && mainMenuActiveCategory.value.name == item.name;
}

function closeMainMenu() {
  mainMenuIsOpen.value = false;
  mainMenuActiveSection.value = null;
  mainMenuActiveSuperCategory.value = null;
  mainMenuActiveCategory.value = null;
}

// Account menu
const accountMenuIsOpen = ref(false);
let accountMenuMouseEnterTimer, accountMenuMouseLeaveTimer;

function handleAccountMenuMouseEnter() {
  if (isSm.value || isMd.value) return false;
  if (moreMenuIsOpen.value) {
    accountMenuIsOpen.value = true;
    nextTick(() => (moreMenuIsOpen.value = false));
  } else {
    clearTimeout(accountMenuMouseLeaveTimer);
    accountMenuMouseEnterTimer = setTimeout(() => (accountMenuIsOpen.value = true), MENU_HOVER_SHOW_DELAY);
  }
}

function handleAccountMenuMouseLeave() {
  if (isSm.value || isMd.value) return false;
  clearTimeout(accountMenuMouseEnterTimer);
  accountMenuMouseLeaveTimer = setTimeout(() => (accountMenuIsOpen.value = false), MENU_HOVER_HIDE_DELAY);
}

// More menu
const moreMenuIsOpen = ref(false);
let moreMenuMouseEnterTimer, moreMenuMouseLeaveTimer;

function handleMoreMenuMouseEnter() {
  if (accountMenuIsOpen.value) {
    moreMenuIsOpen.value = true;
    nextTick(() => (accountMenuIsOpen.value = false));
  } else {
    clearTimeout(moreMenuMouseLeaveTimer);
    moreMenuMouseEnterTimer = setTimeout(() => (moreMenuIsOpen.value = true), MENU_HOVER_SHOW_DELAY);
  }
}

function handleMoreMenuMouseLeave() {
  clearTimeout(moreMenuMouseEnterTimer);
  moreMenuMouseLeaveTimer = setTimeout(() => (moreMenuIsOpen.value = false), MENU_HOVER_HIDE_DELAY);
}

const pageOverlayIsActive = computed(() => mainMenuIsOpen.value || moreMenuIsOpen.value || accountMenuIsOpen.value || showSearchInput.value);

const navMenuToggle = (event, openAccountMenu = false) => {
  event?.preventDefault();
  if (isLg.value && openAccountMenu) {
    window.location = ACCOUNT;
    return false;
  }
  isNavOpen.value = !isNavOpen.value;
  isAccountMenu.value = openAccountMenu;
};

const closeMenu = () => {
  isNavOpen.value = !isNavOpen.value;
  if (isAccountMenu.value) {
    isAccountMenu.value = !isAccountMenu.value;
  }
};
const searchInputToggle = () => {
  showSearchInput.value = !showSearchInput.value;
};
// check for safari broswer
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const dataGAValueForCategory = computed(() => {
  if (!mainMenuActiveSection.value) return null;
  if (mainMenuDropdownHasSupercategories.value) {
    return `${mainMenuActiveSection.value?.name}/${mainMenuActiveSuperCategory.value?.name}/${mainMenuActiveCategory.value?.name}`;
  }

  return `${mainMenuActiveSection.value?.name}/${mainMenuActiveCategory.value?.name}`;
});

const dataGaValueForSuperCat = computed(() => {
  if (!mainMenuActiveSection.value) return null;
  if (mainMenuDropdownHasSupercategories.value) {
    return `${mainMenuActiveSection.value?.name}/${mainMenuActiveSuperCategory.value?.name}`;
  }

  return mainMenuActiveSection.value?.name;
});

// add a delay to activateSuperCategory on mouse enter
function handleSuperCategoryMouseEnter(item) {
  clearTimeout(superCategoryHoverTimer);
  superCategoryHoverTimer = setTimeout(() => activateSuperCategory(item), MENU_ITEM_HOVER_DELAY);
}
// Clear the timer on mouse leave
function handleSuperCategoryMouseLeave() {
  clearTimeout(superCategoryHoverTimer);
}
</script>

<template>
  <header class="relative z-20 h-[var(--site-header-height)] md:h-[var(--site-header-height-md)]">
    <a href="/" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
      <img :src="Logo" alt="Four Hands" class="w-[150px] md:w-[180px]" />
    </a>

    <div class="absolute z-20 h-full w-full bg-white">
      <!-- this empty div is the bottom "border" of the header - needed so it floats over the drop-down menu as it's animating in -->
      <div class="absolute top-full left-0 -z-10 h-px w-full bg-neutral-30"></div>
      <div class="pg-container relative flex h-full items-center bg-white">
        <div class="order-[-13]">
          <button v-if="isSm || isMd" ref="mobileMenuRef" class="order-first" aria-label="Menu" title="Menu" data-ga="MobileMenuIcon" @click="navMenuToggle">
            <FhIcon name="Browse" class="text-2xl" />
          </button>
          <button
            v-if="props.isAuthenticated && isLg"
            aria-label="Search"
            title="Search"
            class="searchIcon"
            :class="{ active: showSearchInput }"
            @click.prevent="searchInputToggle"
          >
            <FhIcon name="Search" class="text-2xl" />
          </button>
        </div>
        <!-- Main nav links -->
        <nav v-if="isLg" class="items-center self-stretch" :class="{ 'ml-f3': props.isAuthenticated }" @mouseleave="handleMainMenuMouseLeave">
          <ul class="flex h-full items-stretch">
            <li v-for="item in mainNavItems" :key="item.name" class="flex items-stretch">
              <a
                class="navItem text-body-lg px-f1.5"
                :class="{
                  'navItem--active': isMainMenuItemActive(item),
                  'first:pl-0': !props.isAuthenticated,
                  'cursor-default': !item.url
                }"
                :href="item.url"
                @mouseenter="(e) => handleMainNavItemMouseEnter(item, e)"
                @mouseleave="handleMainNavItemMouseLeave"
              >
                <span class="navItem__inner">{{ item.name }}</span>
              </a>
            </li>
            <template v-if="!props.isAuthenticated">
              <li class="flex items-stretch">
                <a class="navItem text-body-lg px-f1.5" href="/art-studio" @mouseenter="handleMainMenuMouseLeave">
                  <span class="navItem__inner">Art Studio</span>
                </a>
              </li>
            </template>
          </ul>
        </nav>
        <!-- Spacer -->
        <div class="mx-auto"></div>
        <!-- Trade Program link -->
        <a v-if="isLg && !props.isAuthenticated" href="/to-the-trade" class="text-body-lg navItem self-stretch px-f1.5">
          <span class="navItem__inner"> To the Trade </span>
        </a>
        <a v-if="isLg && !props.isAuthenticated" class="text-body-lg navItem self-stretch px-f1.5" href="/our-locations">
          <span class="navItem__inner">Locations</span>
        </a>

        <!-- "Log In" button -->
        <a v-if="!props.isAuthenticated" href="/login" class="text-body-lg navItem self-stretch px-f1.5" aria-label="Log In" title="Log In">
          <span class="navItem__inner">
            <FhIcon v-if="isSm || isMd" name="User" class="text-2xl" />
            <span v-if="isLg">Log In</span>
          </span>
        </a>
        <!-- Lists button -->
        <a v-if="props.isAuthenticated" href="/shopping-lists" class="navItem -order-1 self-stretch px-f1.5 md:order-none" aria-label="Lists" title="Lists">
          <span class="navItem__inner">
            <FhIcon name="OutlineHeart" class="text-2xl" />
          </span>
        </a>
        <!-- Cart button -->
        <FhNavCart v-if="props.isAuthenticated && props.canAddToCart" class="navItem self-stretch px-f1.5" icon-wrapper-class="navItem__inner" />
        <!-- Account menu -->
        <button
          v-if="props.isAuthenticated"
          class="navItem self-stretch px-f1.5"
          :class="{ 'navItem--active': accountMenuIsOpen }"
          aria-label="Account"
          title="Account"
          @mouseenter="handleAccountMenuMouseEnter"
          @mouseleave="handleAccountMenuMouseLeave"
          @click="navMenuToggle($event, true)"
        >
          <span class="navItem__inner">
            <FhIcon name="User" class="text-2xl" />
          </span>
        </button>
        <!-- More menu button -->
        <button
          v-if="isLg"
          class="navItem self-stretch px-f1.5"
          :class="{ 'navItem--active': moreMenuIsOpen }"
          aria-label="More"
          title="More"
          @mouseenter="handleMoreMenuMouseEnter"
          @mouseleave="handleMoreMenuMouseLeave"
        >
          <span class="navItem__inner">
            <FhIcon name="VerticalEllipse" class="text-2xl" />
          </span>
        </button>
        <!-- account menu dropdown -->
        <Transition :name="moreMenuIsOpen ? null : 'slide'">
          <div
            v-if="accountMenuIsOpen"
            class="absolute right-0 top-full mt-[1px] w-[280px] bg-white p-f3"
            :class="isSafari ? 'z-10' : '-z-10'"
            @mouseenter="handleAccountMenuMouseEnter"
            @mouseleave="handleAccountMenuMouseLeave"
          >
            <ul class="flex flex-col gap-f2">
              <li v-for="(link, index) in props.accountMenu.childItems" :key="index">
                <a
                  :href="link.url"
                  class="relative inline-flex"
                  :target="link.target"
                  :class="{ 'is-active text-neutral-70': link.isActive, group: link.target == '_blank' }"
                >
                  {{ link.name }}
                  <UpRightArrow
                    v-if="link.target === '_blank'"
                    class="absolute left-[calc(100%-5px)] ml-2 w-3.5 md:opacity-0 transition-all duration-200 group-hover:visible group-hover:opacity-100 md:w-4"
                  />
                </a>
              </li>
            </ul>
          </div>
        </Transition>
        <!-- more menu dropdown -->
        <Transition :name="accountMenuIsOpen ? null : 'slide'">
          <div
            v-if="moreMenuIsOpen"
            class="absolute right-0 top-full mt-[1px] w-[280px] bg-white p-f3"
            :class="isSafari ? 'z-10' : '-z-10'"
            @mouseenter="handleMoreMenuMouseEnter"
            @mouseleave="handleMoreMenuMouseLeave"
          >
            <ul class="flex flex-col gap-f2">
              <li v-for="item in props.moreMenu.childItems" :key="item.name" class="text-neutral-70">
                <template v-if="item.childItems && item.childItems.length">
                  <div class="mb-f2 text-neutral-50">{{ item.name }}</div>
                  <ul class="ml-2 flex flex-col gap-f2">
                    <li v-for="childItem in item.childItems" :key="childItem.name">
                      <a :href="childItem.url" class="hover-underline-with-transition">{{ childItem.name }}</a>
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <a :href="item.url" class="hover-underline-with-transition">{{ item.name }}</a>
                </template>
              </li>
            </ul>
          </div>
        </Transition>
      </div>
    </div>
    <FhSiteSearch
      v-if="showSearchInput && isAuthenticated"
      class="absolute left-0 w-full border-b border-b-neutral-30 bg-white site-search-animation"
      :class="{ active: showSearchInput }"
      :is-authenticated="isAuthenticated"
      :search-display-toggle="showSearchInput"
      :is-admin="isAdmin"
      :is-customer-guest="isCustomerGuest"
      @update:search-input-toggle="searchInputToggle"
      @mouseenter="isMouseOverSearch = true"
      @mouseleave="isMouseOverSearch = false"
    />

    <!-- main nav dropdown -->
    <Transition name="slide">
      <div
        v-if="mainMenuIsOpen"
        class="absolute left-0 top-full z-10 w-full bg-white"
        @mouseenter="handleMainMenuMouseEnter"
        @mouseleave="handleMainMenuMouseLeave"
      >
        <!-- main nav dropdown: top section -->
        <div v-if="mainMenuDropdownHasSupercategories" class="border-b border-neutral-30">
          <!-- super category section -->
          <div class="pg-container flex h-[84px] items-stretch">
            <ul class="-mx-f2.5 flex">
              <li v-for="item in mainMenuActiveSection.childItems" :key="item.name" class="flex">
                <a
                  :href="item.url"
                  class="navItem px-f2.5 text-base"
                  data-ga="primary_navigation"
                  :class="[isSuperCategoryActive(item) ? 'navItem--active text-neutral-70' : 'text-neutral-50 hover:text-neutral-70']"
                  :data-ga-nav-path="mainMenuActiveSection?.name"
                  @mouseenter="handleSuperCategoryMouseEnter(item)"
                  @mouseleave="handleSuperCategoryMouseLeave"
                >
                  <span class="navItem__inner" :class="[item.name === 'Sale' ? 'text-sale-red' : '']">{{ item.name }}</span>
                </a>
              </li>
            </ul>
            <ul v-if="mainMenuActiveSection.extraLinks" class="ml-auto flex items-center gap-f3">
              <li v-for="extraLink in mainMenuActiveSection.extraLinks" :key="extraLink.name">
                <a :href="extraLink.url" class="text-body-lg ga-extra-links">{{ extraLink.name }}</a>
              </li>
            </ul>
          </div>
          <!-- end super category section -->
        </div>
        <!-- main nav dropdown: bottom section -->
        <div class="pg-container min-h-[350px] bottom-section max-h-[630px] py-10">
          <div
            :class="{
              'grid-cols-[minmax(200px,1fr)_minmax(150px,1fr)_3fr]': marketingCTAs.primaryCTA?.imageUrl || marketingCTAs.secondaryCTA?.imageUrl,
              'grid-cols-[minmax(200px,1fr)_minmax(150px,1fr)_1fr_1fr]': !marketingCTAs.primaryCTA?.imageUrl || marketingCTAs.secondaryCTA?.imageUrl
            }"
            class="grid grid-flow-col gap-10"
          >
            <div>
              <TransitionGroup name="list" tag="ul" class="flex flex-col gap-3">
                <li v-for="(item, index) in mainMenuActiveSuperCategory.childItems" :key="item.name" :data-index="index" :class="'list-item-' + index">
                  <a
                    :href="item.url"
                    :target="item?.target || `_self`"
                    class="hover-underline-with-transition group relative pb-0.5 text-base hover:text-neutral-70"
                    :class="[isCategoryActive(item) ? 'text-neutral-70 after:w-full' : 'text-neutral-50', { 'cursor-default': !item.url }]"
                    data-ga="secondary_navigation"
                    :data-ga-nav-path="dataGaValueForSuperCat"
                    @mouseenter="activateCategory(item)"
                  >
                    {{ item.name }}
                    <FhIcon v-if="item.hasOwnProperty('childItems')" name="RightChevron" class="relative -top-px" />
                  </a>
                </li>
              </TransitionGroup>
            </div>
            <div>
              <TransitionGroup v-if="mainMenuActiveCategory" name="list" tag="ul" class="flex flex-col gap-f2">
                <li v-for="item in mainMenuActiveCategory.childItems" :key="`${mainMenuActiveCategory.name}_${item.name}`">
                  <a
                    :href="item.url"
                    :target="item?.target || `_self`"
                    :data-ga-nav-path="dataGAValueForCategory"
                    class="hover-underline-with-transition"
                    data-ga="third_navigation"
                    >{{ item.name }}</a
                  >
                </li>
                <li v-if="mainMenuActiveCategory?.childItems?.length > 0 && showAllLink" :key="`${mainMenuActiveCategory.name}_showAllLink`">
                  <a :href="mainMenuActiveCategory.url" class="hover-underline-with-transition" :data-ga-nav-path="dataGAValueForCategory"
                    >All {{ mainMenuActiveCategory.name }}</a
                  >
                </li>
              </TransitionGroup>
            </div>
            <!-- Note: we don't want to show the secondary one if there is only a secondaryCta and no primary -->
            <div v-if="marketingCTAs.primaryCTA?.imageUrl" class="menu__category-image-wrapper grid grid-flow-col gap-10">
              <div class="justify-self-end">
                <a
                  :href="marketingCTAs.primaryCTA?.ctaLink"
                  :target="marketingCTAs.primaryCTA?.ctaTarget || `_self`"
                  data-ga="nav_marketing_banner_primary"
                  :data-ga-nav-path="dataGaValueForSuperCat"
                >
                  <div class="menu__category-image" :style="{ 'background-image': `url(${marketingCTAs.primaryCTA?.imageUrl}` }"></div>
                  <p class="text-neutral-800 text-base underline leading-tight mt-[25px]">{{ marketingCTAs.primaryCTA?.ctaText }}</p>
                </a>
              </div>
              <div v-if="marketingCTAs.secondaryCTA?.imageUrl" class="cta-secondary justify-self-end">
                <a
                  :href="marketingCTAs.secondaryCTA?.ctaLink"
                  :target="marketingCTAs.secondaryCTA?.ctaTarget || `_self`"
                  data-ga="nav_marketing_banner_secondary"
                  :data-ga-nav-path="dataGaValueForSuperCat"
                >
                  <div class="menu__category-image" :style="{ 'background-image': `url(${marketingCTAs.secondaryCTA?.imageUrl}` }"></div>
                  <p class="text-neutral-800 text-base underline leading-tight mt-[25px]">{{ marketingCTAs.secondaryCTA?.ctaText }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- page overlay (behind main nav dropdown)-->
    <Transition name="fade">
      <div
        v-if="pageOverlayIsActive"
        class="fixed top-[49px] left-0 right-0 bottom-0 mix-blend-multiply md:top-[77px] h-screen w-screen bg-black bg-opacity-60"
      ></div>
    </Transition>
  </header>
  <Transition name="nav-menu">
    <FhNav
      v-if="isNavOpen"
      :is-authenticated="props.isAuthenticated"
      :nav-items="mobileNavItems"
      :is-account-menu="isAccountMenu"
      @close="closeMenu"
      @search="tirggerSearch"
    />
  </Transition>
</template>

<style scoped>
:deep(.navItem) {
  @apply flex items-stretch transition-opacity duration-300;
}
:deep(.navItem__inner) {
  @apply relative flex items-center;
}
:deep(.navItem__inner:after) {
  content: "";
  @apply absolute -bottom-px left-0 block h-px w-0 bg-neutral-70 transition-[width] duration-200;
}
:deep(.navItem:hover .navItem__inner:after),
:deep(.navItem--active .navItem__inner:after) {
  @apply w-full;
}
:deep(.searchIcon.isSearch) {
  @apply absolute top-2/4 z-40 -translate-y-2/4;
}

.show-header {
  @apply fixed w-full;
}

.menu__category-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media (min-width: 1200px) {
    height: 336px;
    width: 336px;
  }

  @media (min-width: 1440px) {
    height: 480px;
    width: 480px;
  }

  @media (max-height: 800px) {
    height: 240px;
    width: 240px;
  }
}

.cta-secondary {
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
}
.searchIcon {
  position: relative;
}

.searchIcon::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background-color: black;
  left: 0;
  bottom: -26.5px;
  opacity: 0;
  transition: all 0.3s;
}
.searchIcon:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: black;
  left: 0;
  bottom: -26.5px;
  transition: all 0.3s;
  opacity: 1;
}
.searchIcon.active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: black;
  left: 0;
  bottom: -26.5px;
  transition: all 0.3s;
  opacity: 1;
}
.site-search-animation {
  top: -100%;
  transition: all 0.3s;
}

.site-search-animation.active {
  transition: all 0.3s;
  top: 100%;
}
.nav-menu-enter-active,
.nav-menu-leave-active {
  transition: transform 300ms linear;
}
.nav-menu-enter-from,
.nav-menu-leave-to {
  transform: translateX(-100vw);
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-300;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  animation: menu-slide 0.3s;
}
.slide-leave-active {
  animation: menu-slide 0.3s reverse;
}
@keyframes menu-slide {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.list-leave-active {
  position: absolute;
  animation: fadeOut 0.4s forwards;
  visibility: hidden;
  opacity: 0;
}
.list-leave-to {
  opacity: 0;
}
.list-enter-active {
  animation: fadeIn 0.4s forwards;
  animation-delay: calc(var(--list-index) * 100ms);
}
.list-enter {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.4s;
}
</style>
