<script setup>
import { computed, ref } from "vue";
import { getDataRefs } from "../util/vue";
import FhSection from "../includes/FhSection.vue";
import FhButton from "./FhButton.vue";
import FhVideo from "@/components/FhVideo.vue";
import { useViewportSizes } from "../composables";

const props = defineProps({
  style: {
    type: Object,
    default: null
  },
  data: {
    type: Object,
    required: true
  },
  isVideo: {
    type: Boolean,
    default: false
  }
});

const { isSm, isMd, isLg } = useViewportSizes();
const isTabletOrDesktop = computed(() => isMd.value || isLg.value);
const isMobile = computed(() => isSm.value);

const { layout, image, imageAltText, heading, eyebrowText, description, linkText, linkUrl, linkAltText, linkTarget, gaTagging, skusWhitelist } = getDataRefs(
  props.data
);
const activeSku = ref(null); // Used in the PDP context
function setActiveSku(sku) {
  activeSku.value = sku.skuNumber;
}
defineExpose({ setActiveSku });
</script>

<template>
  <slot name="banner" :set-active-sku="setActiveSku"></slot>
  <FhSection
    v-if="data && (activeSku === null || !skusWhitelist || skusWhitelist.length === 0 || Array.from(skusWhitelist).includes(activeSku))"
    :base="style.base"
    class="overflow-hidden"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <!-- CTA BLOCK FLEX CONTAINER -->
    <div
      class="widget__container grid grid-cols-1 md:grid-cols-2 md:grid-flow-col justify-stretch md:overflow-hidden"
      :class="[layout === 'imageLeft' && isTabletOrDesktop ? 'reverse-cols' : '']"
    >
      <!-- CTA BLOCK CONTENT -->
      <div
        class="widget__containerItem widget__containerItem--text flex-1 flex flex-col items-start mdmax:px-[32px] mdmax:py-[60px] md:p-[40px] tb:p-f15 md:justify-center w-full"
        :class="isMobile ? 'order-2' : ''"
      >
        <!-- CTA BLOCK CONTENT Eyebrow Text -->
        <h2 v-if="eyebrowText" class="text-base font-sans pb-3" aria-level="2">{{ eyebrowText }}</h2>
        <!-- CTA BLOCK CONTENT TITLE -->
        <h5 v-if="heading" class="mb-[32px] font-sans text-3xl" role="heading" aria-level="5">
          {{ heading }}
        </h5>
        <p v-if="description" class="mb-[24px] text-base font-normal" v-html="description"></p>
        <!-- CTA BLOCK LINK -->
        <FhButton
          v-if="linkText != ''"
          class="item-start"
          v-bind="{ href: linkUrl, variant: 'link', inverse: false }"
          :target="linkTarget"
          :aria-label="linkAltText"
          :data-ga="gaTagging"
        >
          {{ linkText }}
        </FhButton>
      </div>
      <!-- CTA BLOCK IMAGE -->
      <div v-if="image" class="widget__containerItem widget__containerItem--image flex-1" :class="isMobile ? 'order-1' : ''">
        <FhVideo v-if="props.isVideo" :src="image" :autoplay="true" :loop="true" :controls="false"></FhVideo>
        <img v-else class="h-full w-full object-cover" :src="image" :alt="imageAltText" />
      </div>
    </div>
  </FhSection>
</template>
<style scoped>
.reverse-cols {
  display: grid;
  direction: rtl;
}
.reverse-cols > * {
  direction: ltr;
}
</style>
