<script setup>
import { computed, ref } from "vue";
import FhCoveoProductCard from "@/components/FhCoveoProductCard.vue";
import FhAddToShoppingListFormModal from "@/components/FhAddToShoppingListFormModal.vue";
import FhShoppingListForm from "@/components/FhShoppingListForm.vue";
import { useUpdateItemMutation, useDeleteShoppingItemMutation, useCreateShoppingListMutation } from "@/api/shoppingListApi";
import { event_AddToWishList } from "@/util/googleAnalytics";
import { useNotificationStore } from "@/stores/NotificationStore";
import { useFeaturesStore } from "@/stores/FeaturesStore";

const notificationStore = useNotificationStore();
const featuresStore = useFeaturesStore();
const hospitality = featuresStore.getFeatureValue("hospitality");

const { isLoading: addToMutationLoading, mutate: addToShoppingListMutation } = useUpdateItemMutation();
const { isLoading: removeItemToMutationLoading, mutate: removeItemToShoppingListMutation } = useDeleteShoppingItemMutation();
const { isLoading: createShoppingListMutationLoading, mutate: createShoppingListMutation } = useCreateShoppingListMutation();

const props = defineProps({
  products: {
    type: Array,
    required: true
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  coveoController: {
    type: Object,
    required: true
  }
});

const validateAndComparePrimaryUrlPath = (plpPathUrl, currentPath) => {
  // Validate plp path exists and we are on a category/shop page
  if (!plpPathUrl || !currentPath.startsWith("/shop/")) {
    return null;
  }

  let plpUrlPath = plpPathUrl.split("/");
  let currentUrlPath = currentPath.replace("/shop/", "").split("/");

  while (plpUrlPath.length > 0) {
    if (plpUrlPath.join("/") === currentUrlPath.join("/")) {
      return true;
    }
    plpUrlPath.pop();
  }

  return false;
};

const handleProductSelected = (product) => {
  try {
    const trackingProduct = {
      ...product,
      position: product.index + 1,
      raw: {
        ...product.additionalFields,
        skuname: product.additionalFields?.skuname
      }
    };

    props.coveoController
      .interactiveProduct({
        options: { product: trackingProduct }
      })
      .select();

    const currentUrlPath = window.location.pathname;
    const catPath = validateAndComparePrimaryUrlPath(product.additionalFields?.primaryPlpUrlPath, currentUrlPath);

    window.location = `/product/${product.permanentid}${!catPath ? `?plp=${currentUrlPath.replace("/shop/", "")}` : ""}`;
  } catch (error) {
    console.error("Error during product interaction:", error);
  }
};

const productsContainer = ref(null);

// Process images and set default
const processImages = (imageArray) => {
  if (!imageArray?.length) return [];
  return imageArray[0]?.split(";") || [];
};

const showAddToShoppingListForm = ref(false);
const shoppingListToModify = ref(null);
const showForm = ref(false);

const markup = ref(props.priceMarkup?.markup || 0);
const showPriceMarkup = ref(props.priceMarkup?.showPriceMarkup || false);
const showCADPricing = ref(props.priceMarkup?.showCADPricing || false);

const transformCoveoProduct = (product, index) => {
  const additionalFields = product.additionalFields || {};
  const children = product.children || [];
  const processedImages = processImages(product.ec_images);
  const defaultImage = additionalFields.imageprimary || processedImages[0];
  const tags = JSON.parse(additionalFields.tags);

  return {
    additionalFields: {
      // Basic fields
      new: additionalFields.new,
      discountpercentage: additionalFields.discountpercentage,
      primarycolor: additionalFields.primarycolor,
      variant_group_data: additionalFields.variant_group_data,
      displayswatchonplp: additionalFields.displayswatchonplp,
      upc: additionalFields.upc,
      item_variant_value: getItemVariantValue(additionalFields.variant_group_data),
      ec_price: additionalFields.ec_price,
      ec_effective_price: additionalFields.ec_effective_price,

      // Images
      imageprimary: defaultImage,
      imagehover: additionalFields.imagehover,
      swatchimage: additionalFields.swatchimage,

      // Product details
      title: additionalFields.title || product.ec_name,
      skuname: additionalFields.skuname,
      material: additionalFields.material,
      permanentid: additionalFields.permanentid || product.permanentid,
      primaryPlpUrlPath: additionalFields.primaryplpurlpath,

      // Status indicators
      availability: additionalFields.availability,
      instock: additionalFields.instock || product.ec_in_stock,
      isdiscounted: additionalFields.isdiscounted,
      closeout: additionalFields.closeout,
      collaboration: additionalFields.collaboration,
      hasperformancefabric: additionalFields.hasperformancefabric,
      tags: hospitality ? tags : tags?.filter((tag) => tag.toLowerCase() !== "hospitality") || [],
      itemtype: additionalFields.itemtype
    },

    // Core product data
    ec_name: product.ec_name,
    ec_description: product.ec_description,
    ec_price: product.ec_price,
    ec_in_stock: product.ec_in_stock,
    ec_images: processedImages,
    ec_category: product.ec_category,
    ec_brand: product.ec_brand,
    ec_product_id: product.ec_product_id,
    ec_color: product.ec_color,
    clickUri: product.clickUri,
    permanentid: product.permanentid,
    index: index,
    ec_effective_price: additionalFields.ec_effective_price,

    // Transform children
    children: children.map((child) => ({
      additionalFields: {
        ...child.additionalFields,
        variant_group_data: child.additionalFields.variant_group_data,
        item_variant_value: getItemVariantValue(child.additionalFields.variant_group_data),
        imageprimary: child.additionalFields.imageprimary || processImages(child.ec_images)[0],
        imagehover: child.additionalFields.imagehover,
        swatchimage: child.additionalFields.swatchimage,
        skuname: child.additionalFields.skuname,
        instock: child.additionalFields.instock || child.ec_in_stock,
        availability: child.additionalFields.availability,
        isdiscounted: child.additionalFields.isdiscounted,
        itemtype: child.additionalFields.itemtype
      },
      ec_name: child.ec_name,
      ec_price: child.ec_price,
      ec_effective_price: child.additionalFields.ec_effective_price,
      ec_in_stock: child.ec_in_stock,
      ec_product_id: child.ec_product_id,
      ec_images: processImages(child.ec_images),
      permanentid: child.permanentid
    }))
  };
};

const transformedProducts = computed(() => props.products.map((product, index) => transformCoveoProduct(product, index)));

const getItemVariantValue = (variant) => {
  if (!variant) {
    return null;
  }
  try {
    const parsedVariant = JSON.parse(variant);
    if (!parsedVariant?.variants?.length) {
      return null;
    }
    const swatchVariant = parsedVariant.variants.find((variant) => variant?.type?.toLowerCase?.() === "swatch");
    return swatchVariant?.value ?? null;
  } catch (error) {
    console.error("Error parsing variant:", error);
    return null;
  }
};

function handleAddToShoppingList(selectedProduct) {
  shoppingListToModify.value = selectedProduct;
  showAddToShoppingListForm.value = true;
}

const toggleShoppingListFormValue = () => {
  showAddToShoppingListForm.value = false;
};

const handaleHeartClickEvent = (item) => {
  const data = {
    skuNumber: item.formData.skuNumber,
    Quantity: 1,
    ShoppingListId: item.item.id
  };
  addToShoppingListMutation(data, {
    onSuccess: () => {
      notificationStore.notifySuccess("Item added to shopping list.");
      event_AddToWishList({
        ...item.formData,
        title: item.item?.title,
        listId: item.item?.guid
      });
    },
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not create that shopping List.")
  });
};
const handleHeartFilledEvent = (item) => {
  const data = {
    skuNumber: item.formData.skuNumber,
    ShoppingListId: item.item.id
  };
  const shoppingListId = data.ShoppingListId;
  const targetSkuNumber = data.skuNumber;
  const targetShoppingList = item.shopping.find((item) => item.id === shoppingListId);
  const matchingProduct = targetShoppingList.products.find((product) => product.skuNumber === targetSkuNumber);
  const product = { ShoppingListID: item.item.id, id: matchingProduct.id, quantity: 1 };
  removeItemToShoppingListMutation(product, {
    onSuccess: () => notificationStore.notifySuccess("Item removed from shopping list."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not create that shopping List.")
  });
};

function addToListModal(val) {
  if (val == true) {
    showAddToShoppingListForm.value = false;
    showForm.value = val;
  } else {
    showAddToShoppingListForm.value = true;
    showForm.value = false;
  }
  showForm.value = val;
}

const createShoppingList = (shoppingListDetails) => {
  const shoppinglist = {
    title: shoppingListDetails.title,
    description: shoppingListDetails.description,
    pricingVisibility: shoppingListDetails.pricingVisibility,
    ShoppingListItems: [{ SkuNumber: shoppingListDetails.skuNumber, Quantity: 1 }]
  };
  createShoppingListMutation(shoppinglist, {
    onSuccess: () => notificationStore.notifySuccess("Your shopping list has been created."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not create that shopping List."),
    onSettled: () => {
      showForm.value = false;
      showAddToShoppingListForm.value = true;
      event_AddToWishList(shoppingListDetails);
    }
  });
};
</script>

<template>
  <div id="productsContainer" ref="productsContainer" class="my-f5 grid grid-cols-2 gap-x-f2.5 gap-y-f10 lg:grid-cols-3">
    <FhCoveoProductCard
      v-for="product in transformedProducts"
      :key="product.additionalFields.permanentid"
      :product="product"
      :is-c-a-d-price="props.isCADPrice"
      :is-customer-guest="props.isCustomerGuest"
      :is-admin="props.isAdmin"
      @addtoshoppinglist="(selectedProduct) => handleAddToShoppingList(selectedProduct)"
      @product-selected="handleProductSelected"
    />

    <FhAddToShoppingListFormModal
      v-if="showAddToShoppingListForm"
      :is-open="showAddToShoppingListForm"
      :shopping-details="shoppingListToModify"
      :is-loading="removeItemToMutationLoading"
      :is-admin="props.isAdmin"
      :is-c-a-d-price="props.isCADPrice"
      :is-customer-guest="props.isCustomerGuest"
      @addtolist="addToListModal"
      @heart-icon-clicked="handaleHeartClickEvent"
      @heart-icon-filled="handleHeartFilledEvent"
      @update:is-open="toggleShoppingListFormValue"
    ></FhAddToShoppingListFormModal>

    <FhShoppingListForm
      v-if="showForm"
      :is-admin="props.isAdmin"
      :is-open="showForm"
      :is-creating="addToMutationLoading || createShoppingListMutationLoading"
      :shopping-details="shoppingListToModify"
      :edit-mode="false"
      :is-plp-pdp="true"
      :markup="markup"
      :show-price-markup="showPriceMarkup"
      :show-c-a-d-pricing="showCADPricing"
      :is-c-a-d-price="props.isCADPrice"
      :is-customer-guest="props.isCustomerGuest"
      @create="createShoppingList"
      @update:is-open="addToListModal"
    ></FhShoppingListForm>
  </div>
</template>
