import { buildCommerceEngine } from "@coveo/headless/commerce";
import { renewToken } from "@/api/coveoApi.js";

const getSearchToken = () => {
  var coveoSearchToken = document.cookie.match("coveo=[^;]+");
  return coveoSearchToken ? coveoSearchToken[0].split("=")[1] : "";
};

const getOrgID = () => {
  const isLowerEnv = ["fh-dev", "fh-test", "localhost"].some((hostName) => window.location.hostname.includes(hostName));
  return isLowerEnv ? "fourhandsnonproduction1yi1gis20" : "fourhandsproduction11ay5s0c";
};

export const headlessCommerceEngine = buildCommerceEngine({
  configuration: {
    organizationId: getOrgID(),
    accessToken: getSearchToken(),
    analytics: {
      trackingId: "fourhands_us"
    },
    context: {
      language: "en",
      country: "US",
      currency: "USD",
      view: {
        url: window.location.origin
      }
    },
    preprocessRequest: (request, clientOrigin) => {
      if (clientOrigin === "commerceApiFetch") {
        const body = JSON.parse(request.body);
        const userType = body.context.view.userType;

        body.context = {
          ...body.context,
          userType,
          showStagingProducts: window.location.hostname.includes("fh-staging")
        };
        request.body = JSON.stringify(body);
      }
      return request;
    },
    renewAccessToken: renewToken
  }
});
