import { ref } from "vue";
import { defineStore } from "pinia";

export const useFacetStore = defineStore("facets", () => {
  const facets = ref();

  const init = (facetObject) => {
    facets.value = [...facetObject];
  };

  return {
    facets,
    init
  };
});
