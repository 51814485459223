<script setup>
import { onMounted, onUnmounted, ref, computed } from "vue";
const props = defineProps({
  class: {
    type: String,
    default: "gap-2 w-64"
  },
  // Length of the gradient in pixels
  fadeLength: {
    type: Number,
    default: 10
  },
  showScrollbar: {
    type: Boolean,
    default: false
  },
  disableAbove: {
    type: Number,
    default: 0
  }
});

const scrollableContainer = ref(null);
const windowWidth = ref(window.innerWidth);
const SCROLLBAR_WIDTH = 4;
const fullFade = `linear-gradient(to right, transparent, black ${props.fadeLength}px, black calc(100% - ${props.fadeLength}px), transparent)`;
const startFade = `linear-gradient(to right, transparent, black ${props.fadeLength}px)`;
const endFade = `linear-gradient(to right, black calc(100% - ${props.fadeLength}px), transparent)`;
const unmaskScrollbar = `linear-gradient(to top, black ${SCROLLBAR_WIDTH}px, transparent ${SCROLLBAR_WIDTH + 1}px)`;
const currentFade = ref(endFade);

const isFadeEnabled = computed(() => {
  return props.disableAbove === 0 || windowWidth.value <= props.disableAbove;
});

const maskImage = computed(() => {
  if (!isFadeEnabled.value) return "";
  return currentFade.value + (props.showScrollbar ? ", " + unmaskScrollbar : "");
});

function setFade(el) {
  if (!isFadeEnabled.value) {
    currentFade.value = "";
    return;
  }

  if (el.scrollLeft === 0) {
    currentFade.value = endFade;
  } else if (Math.ceil(el.scrollLeft + el.clientWidth) >= el.scrollWidth) {
    currentFade.value = startFade;
  } else {
    currentFade.value = fullFade;
  }
}

function updateWindowWidth() {
  windowWidth.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener("resize", updateWindowWidth);
  scrollableContainer.value.addEventListener("scroll", (e) => {
    setFade(e.target);
  });
  setFade(scrollableContainer.value);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWindowWidth);
});
</script>
<template>
  <div class="relative flex overflow-hidden">
    <div
      ref="scrollableContainer"
      :class="['fading-scroll overflow-x-auto flex scroll-smooth', props.class, props.showScrollbar ? 'overflow-x-auto overflow-y-hidden' : 'hide-scrollbar']"
      :style="{
        'mask-image': maskImage,
        'mask-repeat': 'no-repeat no-repeat'
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<style lang="pcss">
.fading-scroll::-webkit-scrollbar {
  @apply h-1;
}
.fading-scroll::-webkit-scrollbar-track {
  background: linear-gradient(0deg, transparent 0%, transparent 25%, rgba(0, 0, 0, 0.35) 25%, rgba(0, 0, 0, 0.35) 75%, transparent 75%, transparent 100%);
}
.fading-scroll::-webkit-scrollbar-thumb {
  @apply bg-black;
}
</style>
