<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { onClickOutside } from "@vueuse/core";
import {
  searchBox as searchBoxController,
  instantProducts as instantProductsController,
  recentQueries as recentQueriesController,
  popularSearchesController,
  updateSearchText,
  resetLastQuery,
  context
} from "@/coveo/controllers";
import FhCoveoSearchBox from "@/components/FhCoveo/FhCoveoSearchBox.vue";
import FhSuggestedSearch from "@/components/SiteHeader/FhSuggestedSearch.vue";
import FhSiteSearchResults from "@/components/SiteHeader/FhSiteSearchResults.vue";
import FhPopularSearchChips from "@/components/FhPopularSearchChips.vue";
import { event_Search } from "@/util/googleAnalytics";
import FhIcon from "@/components/FhIcon.vue";
import FhInput from "@/components/FhInput.vue";
import { useLDFlag } from "launchdarkly-vue-client-sdk";

const props = defineProps({
  searchDisplayToggle: {
    type: Boolean,
    default: false // TODO default to false
  },
  isAuthenticated: {
    //OLD SEARCH
    type: Boolean,
    default: false
  },
  isMobile: {
    type: Boolean,
    default: false
  },
  toggleMenuItems: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: null
  },
  isCustomerGuest: {
    type: Boolean,
    default: null
  }
});
const emit = defineEmits(["update:searchInputToggle", "update:toggleMenuItems"]);

const focusedSearchToggle = ref(false);
const searchRefTarget = ref(null);
const popularSearches = ref([]);

const searchInputElement = ref(null); //OLD SEARCH
const featureFlagKey = "coveo-site-navigation";
const coveo_site_search_flag = useLDFlag(featureFlagKey, false);

const searchText = ref(null); //OLD SEARCH

const searchState = ref(searchBoxController.state);
const recentSearch = ref(recentQueriesController.state);
const productState = ref(instantProductsController.state);
const suggestions = computed(() => searchState.value.suggestions);
const popularSuggestions = computed(() => popularSearches.value.suggestions || []);
const recentSearchQueries = computed(() => recentSearch.value?.queries || []);

const products = computed(() => {
  return searchState.value.value === "" ? [] : productState.value.products;
});

const exactMatch = computed(() => products.value.length === 1);

onMounted(() => {
  context.setView({
    userType: props.isAdmin ? "admin" : props.isCustomerGuest ? "guest" : undefined
  });
  recentQueriesController.subscribe(() => (recentSearch.value = { ...recentQueriesController.state }));
  searchBoxController.subscribe(() => (searchState.value = { ...searchBoxController.state }));
  instantProductsController.subscribe(() => (productState.value = { ...instantProductsController.state }));
  popularSearchesController.subscribe(() => (popularSearches.value = { ...popularSearchesController.state }));
  popularSearchesController.updateText("");
});

const suggestOrRecentClick = (searchTerm) => {
  searchBoxController.selectSuggestion(searchTerm);
  window.location = `/search?q=${encodeURIComponent(searchTerm ?? "")}`;
};

const submitSearch = (_searchValue = null) => {
  let termToSearch = "";
  if (coveo_site_search_flag.value) {
    //Todo remove on go live
    if (instantProductsController.state.products.length === 1 && products.value.length === 1) {
      let product = instantProductsController.state.products.at(0);
      instantProductsController.interactiveProduct({ options: { product } }).select();
      window.location = `/product/${product.ec_product_id}`;
      return;
    }

    termToSearch = searchState.value.value;
    searchBoxController.selectSuggestion(searchState.value.value);
  } else {
    //OLD SEARCH
    termToSearch = searchText.value;
  }

  event_Search(termToSearch);
  searchBoxController.submit();
  window.location = `/search?q=${encodeURIComponent(termToSearch ?? "")}`;
};

const updateSearchState = (newVal) => {
  if (newVal.length > 2) {
    updateSearchText(newVal);
  }
  if (newVal.length === 0) {
    searchBoxController.clear();
    resetLastQuery();
  }
};

const updateFocusedSearchToggle = (newVal) => {
  focusedSearchToggle.value = newVal;
  if (props.isMobile) {
    emit("update:toggleMenuItems", true);
  }
};

const clearRecentSearches = () => {
  recentQueriesController.clear();
};

const productSelected = (product) => {
  console.log("product", product);
  instantProductsController.interactiveProduct({ options: { product } }).select();
  window.location = `/product/${product.ec_product_id}`;
};

onClickOutside(searchRefTarget, () => emit("update:searchInputToggle"));

watch(
  () => props.toggleMenuItems,
  (newVal) => {
    if (newVal === false && props.isMobile) {
      searchBoxController.clear();
    }
  }
);
</script>

<template>
  <div v-if="coveo_site_search_flag" ref="searchRefTarget" class="z-[2]">
    <!-- FEATURE FLAG COVEO-->
    <div v-if="searchDisplayToggle" class="bg-white">
      <section v-if="searchDisplayToggle" class="pg-container search-container">
        <FhCoveoSearchBox
          placeholder-text="Search for products, styles, materials"
          class="z-0"
          :search-state-value="searchState.value"
          :is-mobile="isMobile"
          :is-loading="searchState.isLoading"
          :search-box-id="searchState.searchBoxId"
          @update:search-state="updateSearchState"
          @update:focused-search-toggle="updateFocusedSearchToggle"
          @search="submitSearch"
        >
          <template #close>
            <button
              v-if="!isMobile"
              aria-label="Close Search"
              title="Close"
              class="closeIcon justify-self-center mr-f3"
              @click.prevent="$emit('update:searchInputToggle')"
            >
              <FhIcon name="XIcon" class="text-2xl" />
            </button>
            <button v-else aria-label="Search" title="Search" class="searchIcon justify-self-center mr-f3" @click.prevent="submitSearch">
              <FhIcon name="Search" class="text-2xl" />
            </button>
          </template>
        </FhCoveoSearchBox>
      </section>
      <div class="separator"></div>
      <section
        :class="{ 'gap-f2 py-f5': suggestions.length > 0 || recentSearchQueries.length > 0 || products.length > 0 || popularSuggestions.length > 0 }"
        class="pg-container search-body-container grid grid-cols-1 md:grid-cols-4 bg-white"
      >
        <!--left section: Recent Searches and Suggested Searches-->
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="!exactMatch && (suggestions.length > 0 || recentSearchQueries.length > 0)" class="col-span-4 md:col-span-1">
          <FhSuggestedSearch
            :suggestions="suggestions"
            :recent-search="recentSearchQueries"
            :search-state="searchState"
            @clear-recent-searches="clearRecentSearches"
            @suggestion-click="suggestOrRecentClick"
          ></FhSuggestedSearch>
        </div>
        <!-- Right section: Results-->
        <div class="md:col-span-3 mt-f5 md:mt-0">
          <div v-if="products.length <= 0">
            <FhPopularSearchChips v-if="popularSuggestions" :data="popularSuggestions" />
          </div>
          <div v-else>
            <h3 v-if="products.length > 0" class="text-f-xs-sm text-neutral-50 mb-3 lg:hidden">Suggested Products</h3>
            <FhSiteSearchResults
              :search-results="products"
              :is-customer-guest="isCustomerGuest"
              :is-admin="isAdmin"
              @click:product-selected="productSelected"
            ></FhSiteSearchResults>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div v-else>
    <!-- FEATURE FLAG OLD SEARCH -->
    <!-- Put under feature flag -->
    <div v-if="isAuthenticated && searchDisplayToggle" class="flex w-full h-[76px] relative old-search pg-container">
      <div class="flex w-full">
        <button
          aria-label="Close Search"
          title="Close"
          class="closeIcon justify-self-center mr-f3"
          @click.prevent="() => (isMobile ? submitSearch() : emit('update:searchInputToggle'))"
        >
          <FhIcon :name="isMobile ? 'Search' : 'XIcon'" class="text-2xl" />
        </button>
        <FhInput
          ref="searchInputElement"
          v-model="searchText"
          tabindex="0"
          type="text"
          placeholder="Search for products, styles, materials"
          class="pl-0"
          :wrapper-attrs="{ class: 'h-full w-full pl-f1.5 border-none flex-auto bg-white outline-none' }"
          @keyup.enter="submitSearch"
        />
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.old-search :deep(input) {
  border: none;
  outline: none;
  height: 100%;
}

.old-search .closeIcon + div {
  @apply pl-0;
}

.separator {
  width: 100%;
  display: block;
  height: 1px;
  background-color: #e9e9e9;
  bottom: -1px;
  position: relative;
}
:deep(.popular-search-container) {
  @apply gap-0;
}
:deep(.popular-search-title) {
  @apply text-f-xs-sm text-neutral-50 pb-f3;
}
</style>
