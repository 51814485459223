<script setup>
import { computed } from "vue";

const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  numberOfValues: {
    type: Number,
    default: 6
  }
});
const popularSearch = computed(() => props.data.slice(0, props.numberOfValues));
</script>

<template>
  <div class="popular-search-container flex flex-wrap gap-y-f5 w-full">
    <h2 class="popular-search-title text-xl text-black w-full">Popular Searches</h2>
    <div class="flex flex-wrap gap-f2 w-full">
      <a
        v-for="searchTerm in popularSearch"
        :key="searchTerm.rawValue"
        :href="`/search?q=${encodeURIComponent(searchTerm.rawValue?.trim())}`"
        class="px-4 py-2 border rounded-full border-neutral-30 hover:border-neutral-60 transition-colors duration-300"
      >
        <span class="text-f-sm-lg capitalize">{{ searchTerm.rawValue }}</span>
      </a>
    </div>
  </div>
</template>

<style lang="pcss" scoped></style>
