<script setup>
import { computed, ref, watch } from "vue";
const props = defineProps({
  suggestions: {
    type: Array,
    default: () => []
  },
  recentSearch: {
    type: Array,
    default: () => []
  },
  searchState: {
    type: Object,
    default: () => ({})
  }
});
defineEmits(["clearRecentSearches", "suggestionClick"]);

const hasSuggestions = ref(props.suggestions?.length > 0);
const minimumInputString = ref(props.searchState.value?.length > 2);
const hasRecentSearch = computed(() => props.recentSearch.length > 0);
const showRecentSearch = computed(() => !minimumInputString.value && hasRecentSearch.value);
const viewAllLink = computed(() => `/search?q=${props.searchState.value}`);

watch(
  () => props.searchState.value,
  (newVal) => {
    minimumInputString.value = newVal.length > 2;
  }
);
watch(
  () => props.suggestions,
  (newVal) => {
    hasSuggestions.value = newVal.length > 0;
  }
);
</script>
<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="search-results flex flex-col justify-between h-full">
    <div class="search-queries">
      <div class="grid gap-f2">
        <div v-if="showRecentSearch" class="grid gap-f2">
          <span class="text-f-xs-sm text-neutral-50">Recent Searches</span>
          <div class="flex flex-col gap-f2">
            <span
              v-for="recent in recentSearch"
              :key="recent"
              class="hover-animation cursor-pointer text-sm md:text-lg text-black relative flex items-center"
              @click="$emit('suggestionClick', recent)"
            >
              <span class="hover-animation__inner">{{ recent }}</span>
            </span>
          </div>
        </div>
        <div v-else>
          <div v-if="minimumInputString && hasSuggestions && searchState.isLoadingSuggestions === false" class="gap-f2 grid">
            <span class="text-f-xs-sm text-neutral-50">Suggested Searches</span>
            <span
              v-for="suggestion in suggestions"
              :key="suggestion.rawValue"
              class="suggestions cursor-pointer text-sm md:text-lg inline-block"
              @click="$emit('suggestionClick', suggestion.rawValue)"
              v-html="suggestion.highlightedValue"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="minimumInputString && hasSuggestions && searchState.isLoadingSuggestions === false" class="mt-auto pt-f4">
      <a :href="viewAllLink" class="text-sm text-neutral-70 font-sans font-bold relative flex items-center"
        ><span class="hover-animation__inner">View All Results</span></a
      >
    </div>
    <div v-else-if="showRecentSearch" class="pt-f4 pb-f5">
      <button @click="$emit('clearRecentSearches')">Clear Recent Searches</button>
    </div>
  </div>
</template>

<style lang="pcss">
.hover-animation__inner {
  @apply relative flex items-center;
}
.hover-animation__inner::after {
  content: "";
  @apply absolute -bottom-px left-0 block h-px w-0 bg-neutral-70 transition-[width] duration-200;
}
.hover-animation__inner:hover::after {
  @apply w-full;
}
.suggestions {
  @apply text-neutral-50;
}

.suggestions strong {
  @apply text-neutral-70 capitalize;
}
</style>
