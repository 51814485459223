<script setup>
import { ref, computed } from "vue";
import FhIcon from "./FhIcon.vue";
import FhFormField from "./FhFormField.vue";
import FhButton from "./FhButton.vue";

const props = defineProps({
  modelValue: {
    type: undefined,
    required: true
  },
  type: {
    type: String,
    default: "text",
    validator: (val) => ["text", "password", "tel", "number", "date"].includes(val)
  },
  placeholder: {
    type: String,
    default: undefined
  },
  label: {
    type: String,
    default: undefined
  },
  note: {
    type: String,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: undefined
  },
  /**
   * The Vuelidate validation object for this field. Use this to automatically set the error state and errorMessage.
   * The `errorMessage` prop will override any error messages from this prop if both are set.
   */
  vuelidate: {
    type: Object,
    default: () => undefined
  },
  /**
   * Fallthrough attributes are applied to the inner form control. Use this object if you need to bind anything custom to the form field wrapper node intead.
   */
  wrapperAttrs: {
    type: Object,
    default: () => {}
  },
  withDropDownIcon: {
    type: Boolean,
    default: false
  },
  /**
   * Maximum number of decimal places allowed for number inputs
   */
  maxDecimalPlaces: {
    type: Number,
    default: null
  }
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const isPassword = computed(() => props.type === "password");
const passwordIsVisible = ref(false);
const inputElement = ref(null);

const inputType = computed(() => (isPassword.value && passwordIsVisible.value ? "text" : props.type));

const handleInput = (event) => {
  if (props.type === "number" && props.maxDecimalPlaces !== null) {
    const value = event.target.value;
    if (value.includes(".")) {
      const parts = value.split(".");
      if (parts[1] && parts[1].length > props.maxDecimalPlaces) {
        const truncated = parseFloat(value).toFixed(props.maxDecimalPlaces);
        event.target.value = truncated;
        emit("update:modelValue", parseFloat(truncated));
      }
    }
  }
};

defineExpose({
  inputElement
});
</script>

<template>
  <FhFormField v-bind="{ label, note, disabled, required, error, errorMessage, vuelidate, wrapperAttrs }">
    <template #default="controlBindings">
      <input
        ref="inputElement"
        v-bind="controlBindings"
        v-model="value"
        :type="inputType"
        :placeholder="props.placeholder"
        :readonly="props.withDropDownIcon"
        class="fh-input"
        @input="handleInput"
      />
      <div v-if="props.withDropDownIcon" class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <svg class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </div>
    </template>
    <template v-if="isPassword" #revealPassword>
      <FhButton
        variant="text"
        class="translate-y-1 !pt-0"
        size="small"
        :title="`${passwordIsVisible ? 'Hide' : 'Show'} password`"
        @click="passwordIsVisible = !passwordIsVisible"
      >
        <FhIcon name="PasswordReveal" /> {{ passwordIsVisible ? "Hide" : "Show" }}
      </FhButton>
    </template>
    <template v-if="$slots.note" #note><slot name="note"></slot></template>
    <template v-if="$slots.errorMessage" #errorMessage><slot name="errorMessage"></slot></template>
  </FhFormField>
</template>
