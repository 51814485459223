<script setup>
import { computed, reactive } from "vue";
import FhInput from "@/components/FhInput.vue";
import FhTextarea from "@/components/FhTextarea.vue";
import FhSelect from "@/components/FhSelect.vue";
import FhModal from "@/components/FhModal.vue";
import FhButton from "@/components/FhButton.vue";
import FhImage from "@/components/FhImage.vue";
import { PRICING_LEVELS, PRICING_LEVELS_MARK_UP, CAD_PRICING } from "@/constants";
import formatPrice from "../util/formatPrice.js";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import FhTooltip from "./FhTooltip.vue";
const props = defineProps({
  isCreating: {
    type: Boolean,
    default: false
  },
  editMode: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: true
  },
  shoppingDetails: {
    type: Object,
    default: () => {}
  },
  shoppingListDetails: {
    type: Object,
    required: true
  },
  isPlpPdp: {
    type: Boolean,
    default: false
  },
  markup: {
    type: Number,
    default: 25
  },
  showPriceMarkup: {
    type: Boolean, // Just using this as a feature flag
    default: true
  },
  showCADPricing: {
    type: Boolean, // Just using this as a feature flag
    default: false
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  isAdminShoppingList: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(["create", "update"]);

const initialState = {
  title: null,
  description: null,
  pricingVisibility: null,
  productName: props.shoppingDetails?.name || null,
  skuNumber: props.shoppingDetails?.skuNumber || null,
  thumbUrl: props.shoppingDetails?.thumbUrl || null,
  price: props.shoppingDetails?.price || null,
  name: props.shoppingDetails?.skuName || null
};

const formData = reactive({ ...initialState, ...props.shoppingDetails });
const editMode = computed(() => props.editMode);
const markup = computed(() => (props.markup != null ? props.markup : 0));
const vuelidate = useVuelidate(
  {
    title: { mustHave: helpers.withMessage("Please enter a name for Shopping List.", required) },
    pricingVisibility: {
      mustHave: helpers.withMessage("Please select a pricing level for the Shopping List.", requiredIf(props.isAdmin))
    }
  },
  formData
);

const shouldSeePricingVisibility = computed(() => {
  if (props.isPlpPdp) {
    return props.isAdmin;
  } else {
    return props.isAdmin && props.isAdminShoppingList;
  }
});

//TODO: Feature Price Markup - remove refactor when ready to release or feature is complete
//TODO: Feature CAD MAP/MSRP - remove refactor when ready to release or feature is complete
//Look into turning this into a compostable since its used in a few spots
const pricing_visibilities = computed(() => {
  if (props.showPriceMarkup && props.showCADPricing) {
    return [...PRICING_LEVELS_MARK_UP(markup.value), ...CAD_PRICING];
  } else if (props.showPriceMarkup && !props.showCADPricing) {
    return PRICING_LEVELS_MARK_UP(markup.value);
  } else if (!props.showPriceMarkup && props.showCADPricing) {
    return [...PRICING_LEVELS, ...CAD_PRICING];
  } else {
    return PRICING_LEVELS;
  }
});

const isShoppingList = computed(() => props.isCreating);

const createShoppingList = () => {
  if (editMode.value) {
    emits("update", formData);
  } else {
    emits("create", formData);
  }
};

const reset = () => {
  vuelidate.value.$reset();
  formData.title = null;
  formData.description = null;
  formData.pricingVisibility = null;
};
</script>

<template>
  <FhModal ref="modal" :data="{ heading: editMode != true ? 'Create List' : 'Edit List' }" @close="reset">
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <form id="shoppingListForm" @submit.prevent="createShoppingList">
        <div
          v-if="(isPlpPdp && formData.price != null) || (formData.price != undefined && formData.thumbUrl != null) || formData.thumbUrl != undefined"
          class="mb-6 flex items-center gap-3 md:min-w-0 md:gap-f5"
        >
          <a class="block w-32 flex-none border border-neutral-30 md:w-f25">
            <FhImage :src="formData.thumbUrl" :alt="formData.productName" square :border="true" />
          </a>
          <div class="flex min-w-0 flex-col gap-1">
            <a class="whitespace-pre-line text-f-base-2xl md:mb-f0.5 md:whitespace-normal capitalize">{{ formData.productName }}</a>
            <div class="flex gap-f1.5 text-f-xs-base">
              <span v-if="formData.price"
                ><span v-if="(props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin)">CAD</span
                >{{ formatPrice(formData.price) }}</span
              >
            </div>
            <div class="text-f-xs-base text-neutral-50">
              <span v-if="formData.name">{{ formData.name }}&nbsp;&bull;&nbsp;</span>
              <span v-if="formData.skuNumber">{{ formData.skuNumber }}</span>
            </div>
          </div>
        </div>
        <fieldset class="mb-f8">
          <div class="flex flex-col gap-f3">
            <FhInput v-model="formData.title" label="Name" required :vuelidate="vuelidate.title" />
            <FhTextarea v-model="formData.description" label="Description (optional)" />
            <label v-if="shouldSeePricingVisibility" class="-mb-5 flex gap-f1">
              Pricing Visibility
              <FhTooltip v-if="showPriceMarkup" class="mr-2 ml-2"
                >Select from one of the pre-configured pricing options below or go to My Account to set or update your unique price markup.</FhTooltip
              >
              <span class="text-body-xs leading-6 text-neutral-50"> (Required)</span>
            </label>
            <FhSelect
              v-if="shouldSeePricingVisibility"
              v-model="formData.pricingVisibility"
              :options="pricing_visibilities"
              placeholder="Select a pricing visibility"
              required
              :vuelidate="vuelidate.pricingVisibility"
            />
          </div>
        </fieldset>
      </form>
    </template>
    <template #footer="{ close }">
      <FhButton type="submit" form="shoppingListForm" color="primary" :disabled="vuelidate.$invalid || isShoppingList" :is-loading="isShoppingList">{{
        editMode ? "Update" : "Create List"
      }}</FhButton>
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
</template>

<style scoped>
.leading-6 {
  line-height: 1.5rem !important;
}
</style>
