<script setup>
import { computed, ref, watch } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";
import RegularFacet from "@/components/FhCoveo/FacetType/RegularFacet.vue";
import NumericFacet from "@/components/FhCoveo/FacetType/NumericFacet.vue";
import DateFacet from "@/components/FhCoveo/FacetType/DateFacet.vue";
import CategoryFacet from "@/components/FhCoveo/FacetType/CategoryFacet.vue";
import IconAnimatedUpDownChevron from "@/components/icons/IconAnimatedUpDownChevron.vue";

const props = defineProps({
  showFilterNav: {
    type: Boolean,
    default: false
  },
  filterModel: {
    type: Object,
    required: true,
    default: () => ({})
  },
  updatetotalSelectedFilterOptions: {
    type: Array,
    default: () => []
  },
  totalActiveFilterOptionCount: {
    type: Number,
    default: 0
  }
});

const emits = defineEmits(["update:showFilter", "update:modelValue", "update:sortModel", "clearAll"]);

const facetState = computed(() => props.filterModel);
const hasFacets = computed(() => Object.keys(facetState.value).length > 0);

// Initialize all panels as open by default
const panelState = ref({});
watch(
  facetState,
  (newFacetState) => {
    for (const [index, facetItem] of Object.entries(newFacetState)) {
      const panelId = `panel-facet-${facetItem.state.facetId}-${index}`;
      panelState.value[panelId] = true;
    }
  },
  { immediate: true }
);

// Toggle panel visibility
const togglePanel = (panelId) => {
  panelState.value[panelId] = !panelState.value[panelId];
};

const clearAll = () => {
  // Directly clear all facets before emitting the event
  Object.values(facetState.value).forEach((facet) => {
    if (typeof facet.deselectAll === "function") {
      facet.deselectAll();
    }
  });

  // Then emit clearAll event
  emits("clearAll");
};

const getFacetComponent = (facet) => {
  switch (facet.type) {
    case "regular":
      return RegularFacet;
    case "numericalRange":
      return facet.state.facetId === "ec_effective_price" ? RegularFacet : NumericFacet;
    case "dateRange":
      return DateFacet;
    case "hierarchical":
      return CategoryFacet;
    default:
      return null;
  }
};
</script>

<template>
  <TransitionRoot as="template" :show="showFilterNav">
    <Dialog as="div" class="relative z-10" @close="$emit('update:showFilter', false)">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral-opacity-35 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="border-b border-b-neutral-30 py-5 px-f5">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-f-base-lg font-semibold leading-6">Filters</DialogTitle>
                      <FhButton size="small" variant="round" class="relative" aria-label="Close Filters Sidebar" @click="$emit('update:showFilter', false)">
                        <FhIcon name="XIcon" class="!text-2xl" />
                      </FhButton>
                    </div>
                  </div>
                  <div class="sm:px-6 relative grid h-full flex-1 grid-flow-row grid-rows-[auto_1fr_auto]">
                    <slot name="chips"></slot>
                    <section id="coveoFiltersContainer" class="overflow-y-auto pt-6 px-f5">
                      <slot name="topPanel"></slot>
                      <div v-if="hasFacets">
                        <template v-for="(facetItem, index) in facetState" :key="`facet-${facetItem.state.facetId}-${index}`">
                          <section class="facetItem">
                            <FhButton
                              :id="`panel-btn-facet-${facetItem.state.facetId}-${index}`"
                              variant="pill"
                              size="medium"
                              class="accordion__btn border-none overflow-hidden rounded"
                              :class="{ hoverMenuPanel: panelState[`panel-facet-${facetItem.state.facetId}-${index}`] }"
                              @click="togglePanel(`panel-facet-${facetItem.state.facetId}-${index}`)"
                            >
                              <span>{{ facetItem.state.displayName }}</span>
                              <!-- <FhIcon name="DownChevron" class="flip" aria-hidden="true" /> -->
                              <IconAnimatedUpDownChevron
                                :id="`facet-icon-${facetItem.state.facetId}-${index}`"
                                class="w-[24px] h-[24px]"
                                :trigger-animate="panelState[`panel-facet-${facetItem.state.facetId}-${index}`]"
                                start-position="up"
                              />
                            </FhButton>
                            <div v-show="panelState[`panel-facet-${facetItem.state.facetId}-${index}`]" :id="`panel-facet-${facetItem.state.facetId}-${index}`">
                              <component
                                :is="getFacetComponent(facetItem)"
                                :facet="facetItem"
                                @update:selected-facet-amount="updatetotalSelectedFilterOptions"
                              />
                            </div>
                          </section>
                        </template>
                      </div>
                    </section>
                    <section class="flex h-[180px] items-start justify-center gap-3 py-8 px-3.5 md:px-10">
                      <FhButton class="grow" variant="standard" color="secondary" @click="clearAll">Clear All</FhButton>
                      <FhButton class="grow" variant="standard" color="primary" @click="$emit('update:showFilter', false)">
                        View <template v-if="totalActiveFilterOptionCount > 0">({{ totalActiveFilterOptionCount }})</template>
                      </FhButton>
                    </section>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="pcss">
#coveoFiltersContainer .hoverMenuPanel {
  @apply z-10 max-h-[328px]  overflow-y-auto bg-white pt-f1 pb-f2 max-h-72 mb-f1;
}
#coveoFiltersContainer .accordion__btn {
  @apply flex w-full items-center justify-between py-f2 px-0 text-f-base-lg font-bold;
  &:deep(.icon) {
    font-size: 150%;
  }
}
.facetItem {
  @apply overflow-y-auto py-f2 border-b border-b-neutral-30;
}
#coveoFiltersContainer :deep(.v-slider.v-input--horizontal) {
  margin-inline: 10px 10px;
}

.flip {
  transition: transform 0.2s ease-in-out;
}

.hoverMenuPanel .flip {
  backface-visibility: hidden;
  transition: transform 0.2s ease-in-out;
  transform: scaleY(-1);
}
.panel-filter-list :deep(ul) {
  @apply flex flex-wrap gap-f2;
}

:deep(#coveoFiltersContainer .sortFilter .sortFilter__header) {
  @apply flex justify-between py-f2 px-0 text-f-base-lg font-bold;
}

:deep(#coveoFiltersContainer .sortFilter .sortFilter__header svg) {
  width: 24px;
  height: 24px;
}

:deep(#coveoFiltersContainer .sortFilter .sortFilter__filters li) {
  @apply my-2 w-full;
}

:deep(#coveoFiltersContainer .sortFilter .sortFilter__filters label) {
  @apply w-full;
}
</style>
