<script setup>
import { computed, onMounted, ref, provide } from "vue";
import {
  productListing as productListingController,
  context,
  productListingFacetSubController,
  paginationSubController,
  sortBySubController,
  plpParameterController,
  updateFilteredUrl,
  applyParametersAndFetchProducts,
  getUrlParameters,
  resetSortInteraction
} from "@/coveo/controllers";
import useCoveoSortedFacets from "@/composables/useCoveoSortedFacets";

import FhCoveoProductGrid from "@/components/FhCoveoProductGrid.vue";
import FhCoveoFacet from "@/components/FhCoveo/FhCoveoFacet.vue";
import { useFacetStore } from "@/stores/FacetStore";
import FhButton from "@/components/FhButton.vue";
import FhLoader from "@/components/FhLoader.vue";
import { useInfiniteScroll } from "@/composables/useInfiniteScroll";
import { useHeaderAnimation } from "@/composables/useHeaderAnimation";

const props = defineProps({
  isAdmin: {
    type: Boolean,
    default: false
  },
  priceMarkup: {
    type: Object,
    default: () => {}
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  shouldHidePrice: {
    type: Boolean,
    default: false
  }
});
provide("shouldHidePrice", props.shouldHidePrice);
const { newOrder } = useCoveoSortedFacets();
const { init } = useFacetStore();
const error = ref(null);
const facetsData = ref(productListingFacetSubController.facets);
const productListingState = ref(productListingController.state);
const paginationState = ref(paginationSubController.state);
const isLoading = computed(() => productListingState.value.isLoading);
const products = computed(() => productListingState.value.products);
const totalEntries = ref(paginationState.value.totalEntries);
const scrollThresholdMeet = computed(() => !isLoading.value && products.value?.length % 108 === 0);
const scrollEnd = computed(() => !isLoading.value && products.value.length >= totalEntries.value);

const firstLoad = ref(true);

const observerTarget = ref(null);

const { loadingMore, fetchMoreProduct } = useInfiniteScroll(
  observerTarget,
  paginationSubController.fetchMoreProducts,
  isLoading,
  scrollEnd,
  scrollThresholdMeet
);

// Facet ref for header animation
const facetBarRef = ref(null);
// Initialize header animation without destructuring unused variables
useHeaderAnimation(facetBarRef);

const initializeCoveo = () => {
  // Reset interaction state on page load
  resetSortInteraction();

  const constructUrl = () => {
    const locationManager = window?.location;
    // Always use just the pathname (without query params)
    if (locationManager?.hostname === "localhost") {
      return `https://fourhands.com${locationManager.pathname}`;
    }
    // For non-localhost, use origin + pathname (without query params)
    return `${locationManager.origin}${locationManager.pathname}`;
  };
  try {
    // Use URL directly from props
    context.setView({
      url: constructUrl(),
      referrer: document?.referrer,
      userType: props.isAdmin ? "admin" : props.isCustomerGuest ? "guest" : undefined
    });

    productListingController.subscribe(() => {
      productListingState.value = productListingController.state;
    });
    // Get URL parameters immediately
    const urlParams = getUrlParameters();

    // Set up controller subscriptions
    plpParameterController.subscribe(() => {
      updateFilteredUrl(plpParameterController.state.parameters);
    });
    productListingFacetSubController.subscribe(() => {
      facetsData.value = newOrder(productListingFacetSubController.facets);
      if (firstLoad.value && facetsData.value.length > 0) {
        init(productListingFacetSubController.facets.map((facet) => facet.state));
        firstLoad.value = false;
      }
    });

    paginationSubController.subscribe(() => {
      paginationState.value = paginationSubController.state;
      totalEntries.value = paginationSubController.state.totalEntries;
    });
    // Use the helper function that properly applies parameters and executes request
    applyParametersAndFetchProducts(urlParams);
  } catch (e) {
    //todo: handle error via Sentry
    console.error("Coveo initialization error:", e);
    error.value = productListingController.state.error || e.message;
  } finally {
    isLoading.value = false;
  }
};

const percantageComplete = computed(() => {
  if (products.value) {
    return Math.floor((products.value.length / totalEntries.value) * 100);
  }
  return 0;
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

onMounted(() => {
  initializeCoveo();
});
</script>

<template>
  <div class="product-listing-page">
    <div ref="facetBarRef" class="facet-bar sticky top-0 z-10 flex flex-row gap-7 bg-white justify-between">
      <FhCoveoFacet
        :is-loading="loadingMore"
        :facets="facetsData"
        :total-amount="totalEntries"
        :sort-controller="sortBySubController"
        @clear-all="productListingFacetSubController.deselectAll()"
      />
      <div v-if="props.totalProductsCount > 0" class="ml-auto">{{ props.totalProductsCount }} items</div>
    </div>

    <div v-if="products?.length">
      <FhCoveoProductGrid
        :products="products"
        :coveo-controller="productListingController"
        :is-admin="isAdmin"
        :price-markup="priceMarkup"
        :is-c-a-d-price="isCADPrice"
        :is-customer-guest="isCustomerGuest"
      />
    </div>

    <div v-if="products.length === 0 && !isLoading" class="no-products text-center my-8">
      <p class="py-5 text-center text-xl">There are no results</p>
    </div>

    <!-- Loading State -->
    <div v-if="isLoading || loadingMore" class="flex justify-center items-center mt-f8">
      <FhLoader class="h-9 w-9" />
    </div>

    <div ref="observerTarget" class="h-10 mb-8"></div>

    <!-- Pagination -->
    <div v-if="(scrollThresholdMeet || scrollEnd) && totalEntries > 0" class="flex flex-col justify-center items-center pb-7">
      <div class="mb-8 w-56">
        <span class="text-base font-medium text-neutral-50 block w-full text-center mb-2">Showing {{ products?.length }} of {{ totalEntries }}</span>
        <div class="mb-5 h-2 rounded-full bg-neutral-30">
          <div class="h-2 rounded-full bg-black" :style="{ width: `${percantageComplete}%` }"></div>
        </div>
      </div>
      <div class="flex items-center justify-center gap-3">
        <FhButton class="py-4 px-8" data-fh-ga="scroll-to-top" @click="scrollToTop">Back To Top</FhButton>
        <FhButton v-if="!scrollEnd" color="primary" class="py-4 px-8" data-fh-ga="load-more" :disabled="isLoading || loadingMore" @click="fetchMoreProduct"
          >View More</FhButton
        >
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.facet-bar:after {
  content: "";
  position: absolute;
  width: 100%;
  display: block;
  height: 1px;
  z-index: 0;
  @apply bg-neutral-30 bottom-0 left-0;
}

/* Header animation classes */
.animate-main-content-slide {
  animation: main-content-slide-down 0.3s ease-in-out forwards;
  top: var(--header-height, 0);
}

.animate-main-content-slide-leave {
  /* Adjusted to match perceived speed in FhCoveoResults */
  animation: main-content-slide-up 0.3s ease-in-out forwards;
  top: 0;
}

/* Header animation classes */
:global(header.show-header.animate-header-slide) {
  animation: header-slide-down 0.3s ease-in-out forwards;
  position: fixed;
  width: 100%;
  z-index: 50;
}

:global(header.show-header.animate-header-slide-leave) {
  /* Adjusted to match perceived speed in FhCoveoResults */
  animation: header-slide-up 0.3s ease-in-out forwards;
  position: fixed;
  width: 100%;
  z-index: 50;
}

@keyframes header-slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes header-slide-up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes main-content-slide-down {
  from {
    top: 0;
  }
  to {
    top: var(--header-height, 0);
  }
}

@keyframes main-content-slide-up {
  from {
    top: var(--header-height, 0);
  }
  to {
    top: 0;
  }
}
</style>
