<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import FhButton from "@/components/FhButton.vue";

const props = defineProps({
  facet: {
    type: Object,
    default: () => ({})
  }
});
const state = ref(props.facet?.state);
const controller = computed(() => props.facet);
const isLoading = ref(props.facet?.isLoading);
let unsubscribe = null;

// watch(() => props.facet, (newController) => {
//   controller.value = newController;
// });

const clearSelectedOptions = () => {
  controller.value.deselectAll();
};

onMounted(() => {
  unsubscribe = controller.value.subscribe(() => (state.value = controller.value.state));
});

onUnmounted(() => {
  unsubscribe();
});
</script>

<template>
  {{ facet }}
  <div v-if="!isLoading">
    <div v-for="(item, index) in facet.values" :key="index">
      <div>{{ item }}</div>
    </div>
  </div>
  <div v-else>
    <div>Loading...</div>
  </div>

  <FhButton v-if="hasSelectedOptions > 0" variant="text" class="mt-f1" @click="clearSelectedOptions">Clear</FhButton>
</template>

<style lang="pcss" scoped></style>
