<script setup>
import { ref, computed, inject } from "vue";
import FhIcon from "@/components/FhIcon.vue";
import FhImage from "@/components/FhImage.vue";
import FhProductTags from "@/components/FhProductTags.vue";
import formatPrice from "@/util/formatPrice.js";
import { isMobile } from "mobile-device-detect";

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  }
});

const shouldHidePrice = inject("shouldHidePrice");
const emit = defineEmits(["addtoshoppinglist", "productSelected"]);

const getVariantPrice = (variant) => {
  return Number(variant.ec_price) || Number(variant.additionalFields?.ec_price) || Number(variant.additionalFields?.ec_effective_price) || 0;
};

const handleProductClick = () => {
  const activeVariant = activeSwatch.value;
  let productToEmit = {
    ...props.product,
    index: props.product.index
  };

  if (activeVariant) {
    const variantPrice = getVariantPrice(activeVariant);
    productToEmit = {
      ...activeVariant,
      index: props.product.index,
      ec_name: activeVariant.additionalFields.title || activeVariant.ec_name,
      ec_price: variantPrice,
      ec_effective_price: variantPrice,
      permanentid: activeVariant.additionalFields.permanentid || activeVariant.permanentid
    };
  }

  emit("productSelected", productToEmit);
};

const productInfo = ref({ ...props.product, ...props.product.additionalFields });
const isImageHovered = ref(false);

const hoverSwatch = ref(null);
const selectedSwatch = ref(null);
const activeSwatch = computed(() => hoverSwatch.value ?? selectedSwatch.value);
const activeDetailsObj = computed(() => {
  // Use hovered/selected swatch details or fall back to product details
  if (activeSwatch.value) {
    return activeSwatch.value.additionalFields;
  }
  return productInfo.value;
});
const skuNumber = computed(() => activeDetailsObj.value.permanentid || productInfo.value.additionalFields.permanentid);
const categoryList = computed(() => {
  return []; // TODO: Implement category list for Goole Analytics
});

// Image handling
const defaultImage = computed(() => activeDetailsObj.value.imageprimary);

const hoverImages = computed(() => {
  // First try hovered swatch's hover image
  if (activeSwatch.value) {
    return activeSwatch.value.additionalFields.imagehover;
  }

  // Fallback to product hover image
  return activeDetailsObj.value.imagehover;
});

const imageSrc = computed(() => {
  if (isImageHovered.value) {
    // When hovering, try active swatch image first
    if (activeSwatch.value) {
      return activeSwatch.value.additionalFields.imagehover || defaultImage.value;
    }
    // Otherwise use hover image
    return hoverImages.value || defaultImage.value;
  }
  // Not hovering - use default image
  return defaultImage.value;
});

const url = computed(() => `/product/${skuNumber.value}`);
const groupHasPerformanceOptions = computed(() => {
  return productInfo.value?.children.some((childProduct) => childProduct.additionalFields?.hasperformancefabric == "true");
});

const groupHasNew = computed(() => {
  return productInfo.value?.children.some((childProduct) => childProduct.additionalFields?.new == "true");
});

const TAG_PRIORITY_CONFIG = Object.freeze({
  LIMIT: 2,
  ORDER: ["New", "Closeout", "Hospitality", "Performance Options"],
  SEARCH_TERMS: ["new", "closeout", "hospitality", "performance"],
  // Add mapping for swatch display
  SWATCH_DISPLAY: {
    "Performance Options": "Performance"
  }
});

const hasTag = (tags, searchTerm) => Array.isArray(tags) && tags.some((tag) => tag.toLowerCase().includes(searchTerm));

const getPriorityTags = (rawTags, hasNew = false, hasPerformance = false, isSwatchHovered = false) => {
  // Parse tags if they're in string format
  let processedTags = [];

  if (Array.isArray(rawTags)) {
    processedTags = rawTags;
  } else if (typeof rawTags === "string") {
    try {
      // Try to parse the string as JSON
      processedTags = JSON.parse(rawTags);
    } catch (e) {
      // If parsing fails, use empty array
      processedTags = [];
    }
  }

  const tags = Array.isArray(processedTags) ? processedTags : [];

  // Create map of tag conditions
  const tagConditions = {
    New: hasNew || hasTag(tags, "new"),
    Closeout: hasTag(tags, "closeout"),
    Hospitality: hasTag(tags, "hospitality"),
    "Performance Options": hasPerformance || hasTag(tags, "performance")
  };

  // Get priority tags first
  let priorityTags = TAG_PRIORITY_CONFIG.ORDER.filter((tag) => tagConditions[tag]).slice(0, TAG_PRIORITY_CONFIG.LIMIT);

  // Convert "Performance Options" to "Performance" when hovering swatches
  if (isSwatchHovered) {
    priorityTags = priorityTags.map((tag) => TAG_PRIORITY_CONFIG.SWATCH_DISPLAY[tag] || tag);
  }

  // If we already have max tags, return early
  if (priorityTags.length >= TAG_PRIORITY_CONFIG.LIMIT) {
    return priorityTags;
  }

  // Add remaining non-priority tags until limit
  return [
    ...priorityTags,
    ...tags
      .filter((tag) => !TAG_PRIORITY_CONFIG.SEARCH_TERMS.some((term) => tag.toLowerCase().includes(term)))
      .slice(0, TAG_PRIORITY_CONFIG.LIMIT - priorityTags.length)
  ];
};

const tags = computed(() => {
  const isHovered = activeSwatch.value !== null;
  return getPriorityTags(
    isHovered ? activeSwatch.value.additionalFields.tags : activeDetailsObj.value?.tags,
    isHovered ? activeSwatch.value.additionalFields?.new === "true" : groupHasNew.value,
    isHovered ? activeSwatch.value.additionalFields?.hasperformancefabric === "true" : groupHasPerformanceOptions.value,
    isHovered
  );
});

const alt = computed(() => activeDetailsObj.value.title);
const showEyebrowText = computed(() => activeDetailsObj.value.collaboration === "Amber Lewis x Four Hands");
// Price handling

const ogPriceForDiscount = computed(() => formatPrice(Number(activeDetailsObj.value.ec_price)));
const isDiscounted = computed(() => !!activeDetailsObj.value.isdiscounted && props.isAdmin === true && props.isCustomerGuest === false);

const hasDiscountedVariant = computed(() => {
  if (activeSwatch.value || selectedSwatch.value) {
    const activeItemVariantValue = (activeSwatch.value || selectedSwatch.value).additionalFields.item_variant_value;
    const colorGroup = groupChildrenByItemVariantValueToMakePricingRange.value.find((group) => group.item_variant_value === activeItemVariantValue);
    return colorGroup?.isDiscounted ?? false;
  }

  // If no swatch is active/selected, only show if we're not in a price range
  return !isPriceRange.value;
});

const isPriceRange = computed(() => {
  if (productInfo.value.children.length > 0) {
    const sortedPrices = productInfo.value.children
      .map((child) => {
        return child.additionalFields.ec_effective_price;
      })
      .sort((a, b) => a - b);
    return sortedPrices.at(0) !== sortedPrices.at(-1);
  }
  return false;
});
// const childrenRanges = computed()

const prodcutPriceOrRange = computed(() => {
  // Show specific color group price range when hovering
  if (activeSwatch.value || selectedSwatch.value) {
    const activeItemVariantValue = (activeSwatch.value || selectedSwatch.value).additionalFields.item_variant_value;
    const colorGroup = groupChildrenByItemVariantValueToMakePricingRange.value.find((group) => group.item_variant_value === activeItemVariantValue);

    if (colorGroup) {
      return colorGroup.priceRange.formattedRange;
    }
  }

  // Show price range for products with children when not hovering
  if (productInfo.value.children.length > 0) {
    const sortedPrices = productInfo.value.children
      .map((child) => {
        return child.additionalFields.ec_effective_price;
      })
      .sort((a, b) => a - b);
    if (sortedPrices.at(0) === sortedPrices.at(-1)) {
      return formatPrice(Number(sortedPrices.at(0)));
    }
    return `${formatPrice(Number(sortedPrices.at(0)))} - ${formatPrice(Number(sortedPrices.at(-1)))}`;
  }

  // For single products with no children
  return formatPrice(Number(productInfo.value.ec_effective_price)) || formatPrice(Number(productInfo.value.ec_price));
});

const showDiscountedPrice = computed(() => {
  if (!isDiscounted.value) return false;

  // If we have an active or selected swatch, check if its color group has a single price
  if (activeSwatch.value || selectedSwatch.value) {
    const activeItemVariantValue = (activeSwatch.value || selectedSwatch.value).additionalFields.item_variant_value;
    const colorGroup = groupChildrenByItemVariantValueToMakePricingRange.value.find((group) => group.item_variant_value === activeItemVariantValue);
    return (colorGroup?.priceRange?.isSinglePrice && colorGroup?.isDiscounted) ?? false;
  }

  // If no swatch is active/selected, only show if we're not in a price range
  return !isPriceRange.value;
});
// Stock handling
const inStock = computed(() => props.product.ec_in_stock);

const swatches2 = computed(() => {
  // Initialize groups object to store swatches by name and value
  const groups = {};
  const validSwatches = [];

  // Parse and add parent swatch if it exists
  const parentSwatch = JSON.parse(productInfo.value.additionalFields.displayswatchonplp || "[]");
  if (parentSwatch.length > 0 && parentSwatch[0].name) {
    const groupKey = `${parentSwatch[0].name}-${parentSwatch[0].value}`;
    groups[groupKey] = {
      name: parentSwatch[0].name,
      value: parentSwatch[0].value,
      dam_asset_id: parentSwatch[0].dam_asset_id,
      items: [productInfo.value]
    };
  }

  // Parse and add children swatches if they exist
  if (productInfo.value.children?.length) {
    productInfo.value.children.forEach((child) => {
      try {
        const childSwatch = JSON.parse(child.additionalFields.displayswatchonplp || "[]");
        if (childSwatch.length > 0 && childSwatch[0].name) {
          const groupKey = `${childSwatch[0].name}-${childSwatch[0].value}`;
          if (!groups[groupKey]) {
            groups[groupKey] = {
              name: childSwatch[0].name,
              value: childSwatch[0].value,
              dam_asset_id: childSwatch[0].dam_asset_id,
              items: []
            };
          }
          groups[groupKey].items.push(child);
        }
      } catch (e) {
        console.error("Error parsing child swatch data:", e);
      }
    });
  }

  Object.values(groups).forEach((group) => {
    const dam_asset_id = group.dam_asset_id;
    let validForGroup = false;
    group.items.forEach((item) => {
      const swatchUrl = item.additionalFields.swatchimage;
      if (!validForGroup && typeof swatchUrl === "string" && swatchUrl.includes(dam_asset_id)) {
        validSwatches.push({
          swatchimage: item.additionalFields.swatchimage,
          additionalFields: item.additionalFields
        });
        validForGroup = true;
      }
    });
  });

  // Convert groups object to array
  //  return [Object.values(groups), validSwatches];
  return validSwatches;
});

const groupChildrenByItemVariantValueToMakePricingRange = computed(() => {
  let groupedSwatches = new Map();

  // Group child swatches by their item_variant_value
  if (productInfo.value.children?.length) {
    productInfo.value.children.forEach((swatch) => {
      const item_variant_value = swatch.additionalFields.item_variant_value;
      if (groupedSwatches.has(item_variant_value)) {
        const group = groupedSwatches.get(item_variant_value);
        group.additionalFields.push(swatch.additionalFields);
        // Update isDiscounted if any item is discounted
        group.isDiscounted = group.isDiscounted || Number(swatch.additionalFields.isdiscounted) === 1;
      } else {
        groupedSwatches.set(item_variant_value, {
          item_variant_value: item_variant_value,
          additionalFields: [swatch.additionalFields],
          isDiscounted: Number(swatch.additionalFields.isdiscounted) === 1
        });
      }
    });
  }

  // Add parent swatch to existing group or create new group
  const parentItemVariantValue = productInfo.value.item_variant_value;
  if (groupedSwatches.has(parentItemVariantValue)) {
    const group = groupedSwatches.get(parentItemVariantValue);
    group.additionalFields.push(productInfo.value);
    // Update isDiscounted if parent is discounted
    group.isDiscounted = group.isDiscounted || Number(productInfo.value.isdiscounted) === 1;
  } else {
    groupedSwatches.set(parentItemVariantValue, {
      item_variant_value: parentItemVariantValue,
      additionalFields: [productInfo.value],
      isDiscounted: Number(productInfo.value.isdiscounted) === 1
    });
  }

  // Calculate price ranges for each group
  const result = Array.from(groupedSwatches.values()).map((group) => {
    const prices = group.additionalFields.map((item) => Number(item.ec_effective_price));
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    return {
      ...group,
      priceRange: {
        min: minPrice,
        max: maxPrice,
        isSinglePrice: minPrice === maxPrice,
        formattedRange: minPrice === maxPrice ? formatPrice(minPrice) : `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`,
        ...(minPrice === maxPrice && { originalPriceForDiscount: group.additionalFields[0].ec_price })
      }
    };
  });

  return result;
});

const handleSwatchClick = (swatch) => {
  selectedSwatch.value = swatch;
};

const handleSwatchMouseEnter = (swatch) => {
  hoverSwatch.value = swatch;
};

const handleSwatchMouseLeave = () => {
  hoverSwatch.value = null;
};

const isSwatchSelected = (swatch) => {
  return selectedSwatch.value?.additionalFields?.item_variant_value === swatch.additionalFields?.item_variant_value;
};

const handleHeartClick = () => {
  emit("addtoshoppinglist", getSelectedProductObject());
};

const getSelectedProductObject = () => {
  return {
    name: activeDetailsObj.value.title,
    skuNumber: activeDetailsObj.value.permanentid,
    thumbUrl: activeDetailsObj.value.imageprimary,
    price: Number(activeDetailsObj.value.ec_effective_price),
    skuName: activeDetailsObj.value.item_variant_value,
    categoryList, //TODO: Implement category list for Goole Analytics
    index: productInfo.value?.index,
    inStock: activeDetailsObj.value.instock
  };
};

const isImageHoveredFn = (isHovered) => {
  if (!isMobile) {
    isImageHovered.value = isHovered;
  }
};

const productSwatches = computed(() => {
  return (
    productInfo.value?.children?.filter((childProduct) => {
      const itemType = childProduct.additionalFields?.itemtype || "";
      return itemType.toLowerCase().includes("swatch");
    }) || []
  );
});

// Check if there are any dropdown options
const hasDropdownOptions = computed(() => {
  return productInfo.value?.children?.some((childProduct) => {
    const itemType = childProduct.additionalFields?.itemtype || "";
    return itemType.toLowerCase().includes("drop down");
  });
});

// Check if there are any children at all
const hasOptionsOrStyles = computed(() => productInfo.value?.children?.length > 0);
</script>

<template>
  <component :is="url ? 'a' : 'div'" :href="url" class="block" @click.prevent="handleProductClick">
    <div class="relative group" @mouseenter="isImageHoveredFn(true)" @mouseleave="isImageHoveredFn(false)">
      <!-- Product Tags -->
      <FhProductTags v-if="tags" :tags="tags" class="absolute top-4 left-4 z-[1] hidden md:block" />

      <!-- Product Images -->
      <div class="relative">
        <FhImage :src="imageSrc" :alt="alt" border square class="cursor-pointer" />
      </div>

      <!-- Wishlist Button -->
      <div class="absolute right-1 top-1 md:right-5 md:top-5 lg:hidden lg:group-hover:block">
        <button aria-label="Add to List" class="rounded-full bg-white p-2" @click.stop.prevent="handleHeartClick">
          <FhIcon name="OutlineHeart" class="w-[24px] h-[24px] text-gray-500" />
        </button>
      </div>
    </div>

    <!-- Product Content -->
    <div class="relative flex flex-col px-2.5 py-f3">
      <!-- Eyebrow Text -->
      <div v-if="showEyebrowText" class="text-body-sm font-medium mb-f1 text-neutral-50">Amber Lewis x Four Hands</div>

      <!-- Product Name and Price -->
      <div class="flex grow flex-col md:flex-row md:items-baseline md:justify-between">
        <div class="mb-f1 flex flex-row items-baseline justify-between">
          <div class="mr-f3 text-f-sm-lg capitalize">{{ productInfo.ec_name }}</div>

          <div class="text-xs md:hidden">
            <FhIcon v-if="inStock" name="LightningBolt" class="relative -top-px" />
          </div>
        </div>

        <!-- Price -->
        <div v-if="!shouldHidePrice" class="flex mb-f1 whitespace-nowrap text-f-xs-lg">
          <div v-if="showDiscountedPrice" class="mr-2 line-through">{{ ogPriceForDiscount }}</div>
          <span :class="{ 'text-brand-red': isDiscounted || (isDiscounted && hasDiscountedVariant) }">{{ prodcutPriceOrRange }}</span>
        </div>
      </div>

      <!-- Options and Styles - with updated logic -->
      <div v-if="hasOptionsOrStyles" class="text-body-sm mb-f1 text-neutral-50">
        <span v-if="hasDropdownOptions">More Options</span>
        <span v-else-if="productSwatches.length > 0" class="md:hidden">{{ productSwatches.length }} styles</span>
      </div>

      <!-- Swatches -->
      <div v-if="swatches2?.length > 1" class="mb-f1 flex flex-row flex-wrap">
        <button
          v-for="(swatch, i) in swatches2"
          :key="i"
          class="group hidden items-center justify-center px-0.5 first:-ml-1 md:flex"
          :title="swatch.additionalFields.ec_name"
          @click.stop.prevent="handleSwatchClick(swatch)"
          @mouseenter="handleSwatchMouseEnter(swatch)"
          @mouseleave="handleSwatchMouseLeave()"
        >
          <img
            v-if="swatch.additionalFields.swatchimage"
            :src="swatch.additionalFields.swatchimage"
            :alt="swatch.additionalFields.ec_name"
            class="box-content h-5 w-5 rounded-full border border-solid border-transparent object-cover p-0.5 group-hover:border-neutral-50"
            :class="{ '!border-neutral-70': isSwatchSelected(swatch) }"
          />
        </button>
      </div>

      <!-- Stock Status -->
      <div v-if="inStock" class="text-body-sm items-center hidden md:flex">
        <span class="mr-px">In Stock</span>
        <FhIcon name="LightningBolt" class="relative -top-px" />
      </div>
    </div>
  </component>
</template>

<style scoped>
.group:hover .lg\:group-hover\:block {
  display: block;
}
</style>
