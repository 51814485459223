import { ref, onMounted } from "vue";

export const useInfiniteScroll = (target, loadMoreFn, isLoading, isEnd, scrollThresholdMeet) => {
  const observerTarget = ref(target);
  const loadingMore = ref(false);

  const fetchMoreProduct = () => {
    if (loadingMore.value || isLoading.value) return;
    if (isEnd.value) return;
    if (scrollThresholdMeet.value) scrollThresholdMeet.value = false;

    try {
      loadingMore.value = true;
      loadMoreFn();
    } catch (error) {
      console.error("Error fetching more products", error);
    } finally {
      loadingMore.value = false;
    }
  };

  const setupInfiniteScroll = () => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !scrollThresholdMeet.value) {
        fetchMoreProduct();
      }
    }, options);

    if (observerTarget.value) {
      observer.observe(observerTarget.value);
    }

    return () => {
      if (observerTarget.value) {
        observer.unobserve(observerTarget.value);
      }
    };
  };

  onMounted(setupInfiniteScroll);

  return {
    loadingMore,
    fetchMoreProduct
  };
};
