import { ref, onMounted, onUnmounted } from "vue";

export function useHeaderAnimation(facetBarRef) {
  const lastScrollPosition = ref(0);
  const headerElement = ref(null);

  const initializeAnimation = () => {
    headerElement.value = document.querySelector("header");
    if (headerElement.value) {
      document.documentElement.style.setProperty("--header-height", `${headerElement.value.offsetHeight}px`);
    }
  };

  const handleScroll = () => {
    if (!headerElement.value || !facetBarRef.value) return;

    const currentScrollPosition = window.scrollY;
    const facetBarElement = facetBarRef.value;

    // Check if we've scrolled past the header
    if (currentScrollPosition <= headerElement.value.offsetHeight) {
      if (headerElement.value.classList.contains("show-header")) {
        headerElement.value.classList.remove("show-header");
        headerElement.value.classList.remove("animate-header-slide");
        facetBarElement.classList.remove("animate-main-content-slide");
      }
      return;
    }

    // Handle scrolling up - show the header with animation
    if (currentScrollPosition < lastScrollPosition.value) {
      if (!headerElement.value.classList.contains("show-header")) {
        headerElement.value.classList.add("show-header");
        headerElement.value.classList.add("animate-header-slide");
        facetBarElement.classList.add("animate-main-content-slide");
      } else if (headerElement.value.classList.contains("show-header") && headerElement.value.classList.contains("animate-header-slide-leave")) {
        headerElement.value.classList.remove("animate-header-slide-leave");
        facetBarElement.classList.remove("animate-main-content-slide-leave");
        headerElement.value.classList.add("animate-header-slide");
        facetBarElement.classList.add("animate-main-content-slide");
      }
    }
    // Handle scrolling down - hide the header with animation
    else {
      if (headerElement.value.classList.contains("show-header")) {
        headerElement.value.classList.remove("animate-header-slide");
        facetBarElement.classList.remove("animate-main-content-slide");
        headerElement.value.classList.add("animate-header-slide-leave");
        facetBarElement.classList.add("animate-main-content-slide-leave");
      }
    }

    lastScrollPosition.value = currentScrollPosition;
  };

  onMounted(() => {
    initializeAnimation();
    window.addEventListener("scroll", handleScroll);

    // Use ResizeObserver for more reliable header size tracking
    if (typeof ResizeObserver !== "undefined") {
      const resizeObserver = new ResizeObserver(() => {
        if (headerElement.value) {
          document.documentElement.style.setProperty("--header-height", `${headerElement.value.offsetHeight}px`);
        }
      });

      if (headerElement.value) {
        resizeObserver.observe(headerElement.value);
      }
    }
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
    // Clean up ResizeObserver if needed
    if (typeof ResizeObserver !== "undefined" && headerElement.value) {
      // ResizeObserver cleanup would happen here
    }
  });

  return {
    headerElement,
    lastScrollPosition
  };
}
