<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import FhCheckbox from "@/components/FhCheckbox.vue";
import FhButton from "@/components/FhButton.vue";

const props = defineProps({
  facet: {
    type: Object,
    default: () => ({})
  }
});

const state = ref(props.facet?.state);
const controller = computed(() => props.facet);
const isLoading = computed(() => state.value.isLoading);
let unsubscribe = null;
const hasSelectedOptions = ref(
  state.value.values.some((item) => item.state === "selected") || state.value.selectedValueAncestry.some((item) => item.state === "selected")
);

const onClickFacetSearchResult = (value) => {
  const isselected = controller.value.isValueSelected(value);
  if (isselected) {
    controller.value.deselectAll();
  } else {
    controller.value.toggleSelect(value);
  }
};
const clearSelectedOptions = () => {
  controller.value.deselectAll();
};

onMounted(() => {
  unsubscribe = controller.value.subscribe(() => (state.value = controller.value.state));
});

onUnmounted(() => {
  unsubscribe();
});
</script>

<template>
  <div class="FacetValuesControls">
    <ul class="FacetValues mb-f1 flex flex-col gap-f2">
      <li
        v-for="category in state.values"
        :key="`${state.facetId}-${category.value}-${category.state}`"
        class="flex flex-col w-full items-baseline justify-betweend gap-f2"
      >
        <div class="flex w-full items-center justify-end self-center">
          <FhCheckbox
            :id="`${state.facetId}-${category.value}-${category.state}`"
            :aria-label="`${category.state === 'idle' ? 'Select' : 'Deselect'}`"
            :value="category.value"
            class="ml-f2 w-full text-f-sm-lg mr-f3"
            type="checkbox"
            :model-value="category.state !== 'idle' || controller.isValueSelected(category.value)"
            :disabled="isLoading"
            :label="category.value"
            @update:model-value="onClickFacetSearchResult(category)"
          />
          <div class="text-xs" :class="{ 'text-neutral-50': category.state === 'idle' }">{{ category.numberOfResults }}</div>
        </div>
        <ul v-if="category.children.length > 0" class="flex flex-col w-full justify-end gap-f2">
          <li v-for="child in category.children" :key="`${child.facetId}-${child.value}`" class="flex flex-col gap-f2 pl-6">
            <div class="flex w-full items-center justify-end self-center">
              <FhCheckbox
                :id="`${state.facetId}-${child.value}`"
                :aria-label="`${child.state === 'idle' ? 'Select' : 'Deselect'}`"
                :value="child.value"
                class="ml-f2 w-full text-f-sm-lg mr-f3"
                type="checkbox"
                :model-value="child.state !== 'idle'"
                :disabled="isLoading"
                :label="child.value"
                @update:model-value="onClickFacetSearchResult(child)"
              />
              <div class="text-xs" :class="{ 'text-neutral-50': child.state === 'idle' }">{{ child.numberOfResults }}</div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <FhButton v-if="hasSelectedOptions > 0" variant="text" class="mt-f1" @click="clearSelectedOptions">Clear</FhButton>
  </div>
</template>

<style lang="pcss" scoped>
.FacetValuesControls :deep(.c-checkbox) {
  margin-left: 0;
}

.FacetValues :deep(.c-checkbox) {
  @apply text-f-base-lg;
}
</style>
