<!-- eslint-disable no-unused-vars -->
<script setup>
import FhInput from "@/components/FhInput.vue";
import { onMounted, onUnmounted, ref, watch, computed } from "vue";

const props = defineProps({
  placeholderText: {
    type: String,
    default: "Search"
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  searchBoxId: {
    type: String,
    default: "search-box",
    required: true
  },
  searchStateValue: {
    type: String,
    default: ""
  },
  isMobile: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["search", "update:searchState", "update:focusedSearchToggle"]);
const searchBox = ref(null);
const isSearching = ref(false);

const inputVal = computed({
  get: () => (isSearching.value ? "" : props.searchStateValue),
  set: (val) => {
    emit("update:searchState", val);
    if (val.length > 0) emit("update:focusedSearchToggle", true);
  }
});

const handleSearch = () => {
  const currentValue = inputVal.value;
  if (!currentValue?.trim()) return;

  isSearching.value = true;
  emit("search", currentValue);
  clearInput();
};

const clearInput = () => {
  inputVal.value = "";
  emit("update:searchState", "");
};

watch(
  () => props.searchStateValue,
  (newVal) => {
    if (!isSearching.value) {
      inputVal.value = newVal;
    }
  }
);

onMounted(() => {
  clearInput();
  if (props.isMobile === false) searchBox.value.inputElement.focus();
});

onUnmounted(() => {
  clearInput();
  isSearching.value = false;
});

// Expose clearInput method to parent
defineExpose({ clearInput });
</script>

<template>
  <div class="search-box search-section relative w-full">
    <div class="flex flex-nowrap justify-center">
      <slot name="close"></slot>
      <div class="flex-auto">
        <FhInput
          :id="searchBoxId"
          ref="searchBox"
          v-model="inputVal"
          :placeholder="placeholderText"
          class="h-full w-full pl-f1.5 border-none flex-auto text-black lg:py-f3 text-sm lg:text-base"
          data-search-hub="Search"
          @keyup.enter="handleSearch"
          @click="$emit('update:focusedSearchToggle', true)"
        />
      </div>
    </div>
  </div>
</template>
