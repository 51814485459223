<script setup>
import FhSection from "../includes/FhSection.vue";
import { getDataRefs } from "../util/vue";
import { ref } from "vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { title, description, skusWhitelist } = getDataRefs(props.data);
const activeSku = ref(null); // Used in the PDP context
function setActiveSku(sku) {
  activeSku.value = sku.skuNumber;
}
defineExpose({ setActiveSku });
</script>

<template>
  <slot name="banner" :set-active-sku="setActiveSku"></slot>
  <FhSection
    v-if="data && (activeSku === null || !skusWhitelist || skusWhitelist.length === 0 || Array.from(skusWhitelist).includes(activeSku))"
    aria-label="items"
    :base="style.base"
    class="pb-f3 pt-f3"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="grid md:justify-items-center text-center justify-center mb-f5">
      <div class="md:max-w-[600px] md:basis-1/2 mb-[32px]">
        <h2 class="font-sans text-f-xl-3xl">{{ title }}</h2>
      </div>
      <p class="text-f-sm-base font-normal md:max-w-[600px] md:basis-1/2">{{ description }}</p>
    </div>
    <div
      :class="[
        'grid grid-cols-2 gap-f4 md:gap-x-f3 gap-y-f6 md:gap-y-f4',
        data.layout === 'normal' ? 'lg:grid-cols-4' : '',
        data.layout === 'small' ? 'lg:grid-cols-6' : ''
      ]"
    >
      <div class="flex flex-col" :class="[data.layout === 'small' ? 'lg:col-start-2' : '']">
        <div class="">
          <img :src="data.image1" class="h-auto w-full object-cover" :alt="data.image1AltText" />
        </div>
        <div v-if="!!data.optionalSubcopy1" class="pt-[24px] h-full">
          <p :class="['text-f-sm-base font-normal ', data.subcopyTextAlignment]">{{ data.optionalSubcopy1 }}</p>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="">
          <img :src="data.image2" class="h-auto w-full object-cover" :alt="data.ima2AltText" />
        </div>
        <div v-if="!!data.optionalSubcopy2" class="pt-[24px] h-full">
          <p :class="['text-f-sm-base font-normal ', data.subcopyTextAlignment]">{{ data.optionalSubcopy2 }}</p>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="">
          <img :src="data.image3" class="h-auto w-full object-cover" :alt="data.ima3AltText" />
        </div>
        <div v-if="!!data.optionalSubcopy3" class="pt-[24px] h-full">
          <p :class="['text-f-sm-base font-normal ', data.subcopyTextAlignment]">{{ data.optionalSubcopy3 }}</p>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="">
          <img :src="data.image4" class="h-auto w-full object-cover" :alt="data.ima4AltText" />
        </div>
        <div v-if="!!data.optionalSubcopy4" class="pt-[24px] h-full">
          <p :class="['text-f-sm-base font-normal ', data.subcopyTextAlignment]">{{ data.optionalSubcopy4 }}</p>
        </div>
      </div>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.text-white * {
  color: white !important;
}
</style>
