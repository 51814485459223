<script setup>
import { computed } from "vue";
import FhIcon from "@/components/FhIcon.vue";

const props = defineProps({
  currentSelectedFacetValues: {
    type: Object,
    default: () => ({})
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});
const isLoading = computed(() => props.isLoading);

// Flatten the facet data into a single array of selected options
const selectedOptions = computed(() => props.currentSelectedFacetValues);

const unicodeConverter = (value) => {
  return value.replace(/\\u([0-9A-Fa-f]{4})/g, (match, code) => {
    return String.fromCharCode(parseInt(code, 16));
  });
};

const handleRemoveRegularChip = (facet, value) => {
  facet.toggleSelect(value);
};

const handleRemoveHierarchicalChip = (facet) => {
  facet.deselectAll();
};

const handleRemoveNumericalRangeChip = (facet, value = null) => {
  facet.toggleSelect(value);
};

const getDisplayNameForNumericalRange = (state, value) => {
  if (state.facetId === "ec_effective_price") {
    return `${state.displayName}: $${value.start} - $${value.end}`;
  }
  if (state.facetId === "width" || state.facetId === "weight" || state.facetId === "height" || state.facetId === "depth") {
    return `${state.displayName}: ${value.start}" - ${value.end}"`;
  }
  if (state.facetId === "seatingcapacity") {
    return `${state.displayName}: ${value.start} - ${value.end}`;
  }
  return state.displayName;
};

defineEmits(["update:modelValue"]);
</script>

<template>
  <section v-if="true" data-product-chips class="filter-list overflow-x-auto">
    <ul class="flex flex-nowrap justify-start gap-4 md:flex-wrap">
      <li v-for="option in selectedOptions" :key="option.value.value + option.value.state" class="flex flex-nowrap justify-start gap-4 md:flex-wrap">
        <button
          v-if="option.facet.type === 'regular' || option.facet.id === 'ec_effective_price'"
          class="flex cursor-pointer items-center gap-1 border-none text-f-xs-sm font-bold text-neutral-50 md:font-bold"
          :disabled="isLoading"
          @click="handleRemoveRegularChip(option.facet, option.value)"
        >
          <span class="whitespace-nowrap capitalize">{{ unicodeConverter(option.value.value) }}</span>
          <span><FhIcon name="XIcon" class="!text-lg" /></span>
        </button>

        <button
          v-if="option.facet.type === 'hierarchical'"
          class="flex cursor-pointer items-center gap-1 border-none text-f-xs-sm font-bold text-neutral-50 md:font-bold"
          :disabled="isLoading"
          @click="handleRemoveHierarchicalChip(option.facet)"
        >
          <span class="whitespace-nowrap capitalize">{{ unicodeConverter(option.value.value) }}</span>
          <span><FhIcon name="XIcon" class="!text-lg" /></span>
        </button>

        <button
          v-if="option.facet.type === 'numericalRange'"
          class="flex cursor-pointer items-center gap-1 border-none text-f-xs-sm font-bold text-neutral-50 md:font-bold"
          :disabled="isLoading"
          @click="handleRemoveNumericalRangeChip(option.facet, option.value)"
        >
          <span class="whitespace-nowrap capitalize">{{ getDisplayNameForNumericalRange(option.facet.state, option.value) }}</span>
          <span><FhIcon name="XIcon" class="!text-lg" /></span>
        </button>
      </li>
      <slot></slot>
    </ul>
  </section>
</template>
