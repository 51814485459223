<script setup>
import { computed, ref } from "vue";
import FhRadio from "@/components/FhRadio.vue";
import IconAnimatedUpDownChevron from "@/components/icons/IconAnimatedUpDownChevron.vue";
import { markSortAsInteractedWith } from "@/coveo/controllers";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
    default: ""
  },
  sortOptions: {
    type: Array,
    required: true,
    default: () => []
  },
  isMenuOpen: {
    type: Boolean,
    default: false
  },
  keepMenuOpen: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);
const sortRef = ref(null);

const sortOptions = computed(() => props.sortOptions);
const showSortOptions = ref(props.isMenuOpen);

const sortModel = computed({
  get: () => props.modelValue,
  set: (value) => {
    // Mark sort as interacted with when user changes sort
    markSortAsInteractedWith();
    emit("update:modelValue", value);
  }
});

const sortName = computed(() => sortOptions.value.find((option) => option.key == sortModel.value));

const sortBlur = (event) => {
  const eventTarget = event.relatedTarget;
  if (eventTarget && showSortOptions.value) {
    sortModel.value = eventTarget.value;
  }
  if (!props.keepMenuOpen) showSortOptions.value = false;
};
</script>
<template>
  <section ref="sortRef" class="sortFilter" tabindex="0" @blur="sortBlur">
    <div class="sortFilter__header cursor-pointer" @click.stop="showSortOptions = !showSortOptions">
      <div class="sortFilter__header-title">
        Sort By: <span class="sortFilter__header-title--descriptor">{{ sortName?.name }}</span>
      </div>
      <IconAnimatedUpDownChevron class="w-5 h-5" :trigger-animate="showSortOptions" start-position="down" />
    </div>
    <div v-if="showSortOptions" class="sortFilter__filters">
      <ul>
        <li v-for="sortOption in sortOptions" :key="sortOption.key">
          <FhRadio v-model="sortModel" class="sortFilter__filter text-f-base-lg" :value="sortOption.key" :label="sortOption.name" />
        </li>
      </ul>
    </div>
  </section>
</template>
<style lang="pcss" scoped>
.sortFilter :deep(.sortFilter__filter) {
  @apply text-f-base-lg;
}
</style>
