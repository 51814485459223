<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import FhCheckbox from "../../FhCheckbox.vue";
import FhButton from "@/components/FhButton.vue";
import { useFacetStore } from "@/stores/FacetStore";
import formatPrice from "@/util/formatPrice";
import { useFeaturesStore } from "@/stores/FeaturesStore";

const props = defineProps({
  facet: {
    type: Object,
    default: () => ({})
  }
});

const featuresStore = useFeaturesStore();

const showHospitality = (facetValue) => {
  if (facetValue.toLowerCase() === "hospitality") {
    return featuresStore.getFeatureValue("hospitality");
  }
  return true;
};

const facetsInStore = useFacetStore();
const emits = defineEmits(["update:selectedFacetAmount"]);
const state = ref(props.facet.state);
const isPriceRange = computed(() => state.value.facetId === "ec_effective_price");
const selectCurrentFacetInStore = computed(() => {
  return facetsInStore.facets?.find((facet) => facet.facetId === state.value.facetId);
});
const controller = computed(() => props.facet);
const isLoading = computed(() => state.value.isLoading);
const hasSelectedOptions = computed(() => state.value.values.some((item) => item.state === "selected"));
let unsubscribe = null;

const onClickFacetSearchResult = (value) => {
  controller.value.toggleSelect(value);
};

const clearSelectedOptions = () => {
  controller.value.deselectAll();
};

const facetWrapper = computed(() => {
  // Get current search results and add missing store values
  const combinedValues = [...state.value.values];
  selectCurrentFacetInStore.value?.values.forEach((element) => {
    if (!combinedValues.some((value) => value.value === element.value)) {
      combinedValues.push({
        value: isPriceRange.value ? `${element.start} - ${element.end}` : element.value,
        state: "idle",
        numberOfResults: 0,
        disabled: true
      });
    }
  });

  // Don't sort price ranges or availability facets
  if (isPriceRange.value || state.value.facetId === "productavailability") {
    return combinedValues;
  }

  // Sort by numberOfResults in descending order (highest to lowest)
  return combinedValues.sort((a, b) => b.numberOfResults - a.numberOfResults);
});

// Add a new computed property for display values
const displayFacetValues = computed(() => {
  return facetWrapper.value.filter((value) => value.value !== "Arrives in +60 Days" && value.value.toLowerCase() !== "one size");
});

const unicodeConverter = (value) => {
  return value.replace(/\\u([0-9A-Fa-f]{4})/g, (match, code) => {
    return String.fromCharCode(parseInt(code, 16));
  });
};
onMounted(() => {
  unsubscribe = controller.value.subscribe(() => (state.value = controller.value.state));
});

onUnmounted(() => {
  unsubscribe();
});

const formactPricingLabel = (value) => {
  var startPrice = formatPrice(value.start);
  var endPrice = formatPrice(value.end);
  return `${startPrice} - ${endPrice}`;
};

watch(
  () => state.value,
  () => {
    emits("update:selectedFacetAmount");
  }
);
</script>

<template>
  <div class="FacetValuesControls">
    <ul v-if="!isPriceRange" class="FacetValues mb-f1 flex flex-col gap-f2 pr-2">
      <template v-for="value in displayFacetValues" :key="`${state.facetId}-${value.value}`">
        <li v-if="showHospitality(value.value)" class="flex w-full items-center justify-betweend">
          <FhCheckbox
            v-if="value.value"
            :id="`${state.facetId}-${value.value}`"
            :aria-label="`${value.state === 'idle' ? 'Select' : 'Deselect'}`"
            :value="value.value"
            class="w-full mr-f3"
            type="checkbox"
            :model-value="value.state !== 'idle'"
            :disabled="isLoading || value?.disabled || value.numberOfResults === 0"
            :label="unicodeConverter(value.value)"
            @update:model-value="onClickFacetSearchResult(value)"
          />
          <div v-if="value.value" class="text-xs" :class="{ 'text-neutral-50': value.state === 'idle' }">{{ value.numberOfResults }}</div>
        </li>
      </template>
    </ul>
    <ul v-else class="FacetValues mb-f1 flex flex-col gap-f2 pr-2">
      <li v-for="value in facetWrapper" :key="`${state.facetId}-${value.start}`" class="flex w-full items-center justify-betweend">
        <FhCheckbox
          :id="`${state.facetId}-${value.start}`"
          :aria-label="`${value.state === 'idle' ? 'Select' : 'Deselect'}`"
          :value="value.start"
          class="ml-f2 w-full"
          type="checkbox"
          :model-value="value.state !== 'idle'"
          :disabled="isLoading || value?.disabled || value.numberOfResults === 0"
          :label="formactPricingLabel(value)"
          @update:model-value="onClickFacetSearchResult(value)"
        />
        <div v-if="value.start || value.start >= 0" class="text-xs" :class="{ 'text-neutral-50': value.state === 'idle' }">{{ value.numberOfResults }}</div>
      </li>
    </ul>
    <FhButton v-if="hasSelectedOptions" variant="text" class="mt-f1" @click="clearSelectedOptions">Clear</FhButton>
  </div>
</template>

<style lang="pcss" scoped>
.FacetValuesControls :deep(.c-checkbox) {
  margin-left: 0;
}
.FacetValues {
  @apply md:max-h-72 md:overflow-y-auto mb-f1;
}
.FacetValues :deep(.c-checkbox) {
  @apply text-f-base-lg;
}
.FacetValues :deep(.checkbox) {
  @apply top-0 bottom-0 mt-auto mb-auto mr-f2;
}
</style>
