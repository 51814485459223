<script setup>
import { ref, computed } from "vue";
import { useCartQuery, useAddCartItemsMutation, useUpdateCartItemMutation, useRemoveCartItemMutation } from "@/api/cartApi";
import { useAddSaveForLaterItemsMutation } from "@/api/saveforlaterApi";
import { useNotificationStore } from "@/stores/NotificationStore.js";
import { CoveoCartAction } from "@/util/coveoAnalytics.js";
import FhCartPresenter from "./FhCartPresenter.vue";
import FhSaveForLater from "./FhSaveForLater.vue";

const props = defineProps({
  shippingTooltip: {
    type: String,
    default: null
  },
  taxTooltip: {
    type: String,
    default: null
  },
  cartMessaging: {
    type: String,
    default: null
  }
});

const notificationStore = useNotificationStore();

const itemKeyTouches = ref({});

function getProductNameByItemId(itemId) {
  return items.value.find((item) => item.id === itemId)?.sku.productName;
}

/**
 * Cart Query
 */

const { isLoading, isError, data: cart } = useCartQuery();

function generateItemKey(itemId) {
  const touches = itemKeyTouches.value[itemId] || 0;
  return `${itemId}-${touches}`;
}

const items = computed(() => cart.value?.items.map((item) => ({ ...item, key: generateItemKey(item.id) })) || []);

const totalItemsCount = computed(() => cart.value?.totalItemsCount || 0);

const totalItemsPrice = computed(() => cart.value?.totalItemsPrice || 0);

const availabilityLastUpdatedDate = computed(() => (cart.value ? new Date(cart.value.availabilityLastUpdatedDate) : null));

/**
 * Update Cart Item Mutation
 */

const updateCartItemMutation = useUpdateCartItemMutation();

function touchItemKey(itemId) {
  itemKeyTouches.value = {
    ...itemKeyTouches.value,
    [itemId]: (itemKeyTouches.value[itemId] || 0) + 1
  };
}

function handleItemQuantityUpdate(item) {
  const itemToUpdate = items.value.find((cartItem) => cartItem.id === item.id);
  const updateType = itemToUpdate.quantity > item.quantity ? "remove" : "add";

  updateCartItemMutation.mutate(item, {
    onSuccess: () => {
      notifyItemQuantityUpdateSuccess(item.id);
      const updatedItem = items.value.find((cartItem) => cartItem.id === item.id);
      CoveoCartAction(updatedItem, updateType);
    },
    onError: () => {
      // Forces the CartItem component to rebind, so the quantity is reset back to its pre-mutation value
      touchItemKey(item.id);
      notifyItemQuantityUpdateError(item.id);
    }
  });
}

function notifyItemQuantityUpdateSuccess(itemId) {
  notificationStore.notifySuccess(`${getProductNameByItemId(itemId)} quantity updated.`);
}

function notifyItemQuantityUpdateError(itemId) {
  notificationStore.notifyError(`Sorry, something went wrong and we could not update the quantity of ${getProductNameByItemId(itemId)}. Please try again.`);
}

/**
 * Remove Cart Item Mutation
 */

const removeCartItemMutation = useRemoveCartItemMutation();

async function handleItemDelete(item) {
  let cartItem = items.value.find((x) => x.id === item.id);
  try {
    await removeCartItemMutation.mutateAsync(item);
    notifyItemRemoveSuccess(cartItem);
    CoveoCartAction(cartItem, "remove");
  } catch {
    notifyItemRemoveError(cartItem);
  }
}

function notifyItemRemoveSuccess(cartItem) {
  let notificationSettings;

  // Don't offer the "Undo" link for unavailable items
  if (cartItem.sku.isAvailable !== false) {
    notificationSettings = {
      linkText: "Undo",
      onLinkClick: () => undoItemRemove(cartItem)
    };
  }

  notificationStore.notifySuccess(`${cartItem.sku.productName} removed.`, notificationSettings);
}

function notifyItemRemoveError(cartItem) {
  notificationStore.notifyError(`Sorry, something went wrong and we could not remove ${cartItem.sku.productName}. Please try again.`);
}

/**
 * Add Cart Item Mutation (to support undoing of a remove)
 */

const addCartItemsMutation = useAddCartItemsMutation();

function undoItemRemove(cartItem) {
  const data = [{ skuNumber: cartItem.sku.skuNumber, quantity: cartItem.quantity }];
  addCartItemsMutation.mutate(data, {
    onSuccess: () => notifyUndoSuccess(cartItem.sku.productName),
    onError: () => notifiyUndoError(cartItem.sku.productName)
  });
}

const shouldScrollToItem = ref(false);

function notifyUndoSuccess(productName) {
  notificationStore.notifySuccess(`${productName} added back.`);
  shouldScrollToItem.value = true;
}

function notifiyUndoError(productName) {
  notificationStore.notifyError(`Sorry, something went wrong and we could not add ${productName} back.`);
}

function handleItemEnter(itemElement) {
  if (shouldScrollToItem.value) {
    shouldScrollToItem.value = false;
    itemElement.scrollIntoView({ block: "start", behavior: "smooth" });
  }
}
const SaveForLatersMutation = useAddSaveForLaterItemsMutation();

async function handleSaveForLater(itemToMove) {
  let cartItem = items.value.find((cartItem) => cartItem.id === itemToMove.id);
  try {
    await SaveForLatersMutation.mutateAsync(cartItem);
    notificationStore.notifySuccess(`${cartItem.sku.productName} added to Save for Later.`);
  } catch {
    notificationStore.notifyError(`Sorry, something went wrong and we could not add to Save for Later.`);
  }
}
</script>

<template>
  <FhCartPresenter
    :is-loading="isLoading"
    :is-error="isError"
    :items="items"
    :total-items-count="totalItemsCount"
    :total-items-price="totalItemsPrice"
    :availability-last-updated-date="availabilityLastUpdatedDate"
    :shipping-tooltip="props.shippingTooltip"
    :tax-tooltip="props.taxTooltip"
    :cart-messaging="props.cartMessaging"
    @item-quantity-update="handleItemQuantityUpdate"
    @item-delete="handleItemDelete"
    @item-enter="handleItemEnter"
    @saveforlater="handleSaveForLater"
  />
  <FhSaveForLater></FhSaveForLater>
</template>
