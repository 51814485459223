export const CoveoProductView = (product) => {
  const eventProps = {
    product: {
      productId: product.sku,
      name: product.name,
      price: product.price
    },
    currency: "USD"
  };

  window.dataLayer.push({
    event: "ec.productView",
    eventProps
  });
};

export const CoveoCartAction = (cartItem, actionType) => {
  const eventProps = {
    action: actionType,
    currency: cartItem?.currency || "USD",
    quantity: cartItem.quantity || 1,
    product: {
      productId: cartItem.sku?.skuNumber,
      name: cartItem.sku?.productName,
      price: cartItem.unitPrice || 0
    }
  };

  window.dataLayer.push({
    event: "ec.cartAction",
    eventProps
  });
};
