<script setup>
import { computed, ref, nextTick, watch } from "vue";
import FhGuestAccountListPresenter from "@/components/FhGuestAccountListPresenter.vue";
import FhModal from "@/components/FhModal.vue";
import FhButton from "@/components/FhButton.vue";
import FhLoader from "@/components/FhLoader.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhGuestAccountForm from "@/components/FhGuestAccountForm.vue";
import { useGuestAccountQuery, useDeleteGuestAccountMutation, useCreateGuestAccountMutation, useUpdateGuestAccountMutation } from "@/api/accountApi";
import { useNotificationStore } from "@/stores/NotificationStore";
import FhPaginationNav from "@/components/FhPaginationNav.vue";
import { usePaginatedList } from "@/composables";
import scrollToTopOf from "@/util/scrollToTopOf";
import FhInput from "@/components/FhInput.vue";
import FhIcon from "@/components/FhIcon.vue";

const props = defineProps({
  perPage: {
    type: Number,
    default: 12
  },
  priceMarkup: {
    type: Object,
    default: () => {}
  }
});

const showDeleteModal = ref(false);
const showAccountForm = ref(false);
const guestAccountIdToDelete = ref(null);
const isEditForm = ref(false);
const guestAccountToModify = ref(null);
const rootElem = ref(null);
const PER_PAGE = ref(props.perPage);
const showPriceMarkup = ref(props.priceMarkup?.showPriceMarkup || false);
const markup = ref(props.priceMarkup.markup);
const showCADPricing = ref(props.priceMarkup?.showCADPricing || false);
const notificationStore = useNotificationStore();
let { isLoading, isError, data: guestAccountsData } = useGuestAccountQuery();
const { isLoading: deleteMutationLoading, mutate: deleteAccountMutate } = useDeleteGuestAccountMutation();
const { isLoading: createMutationLoading, mutate: createAccountMutate, error: createAccountError } = useCreateGuestAccountMutation();
const { isLoading: updateMutationLoading, mutate: updateAccountMutate } = useUpdateGuestAccountMutation();

const guestAccounts = computed(() => (isLoading.value ? [] : guestAccountsData.value));

const searchQuery = ref("");
const filteredItems = computed(() => {
  const query = searchQuery.value.toLowerCase().trim();
  if (!query) {
    return guestAccounts.value;
  }
  return guestAccounts.value.filter((item) => {
    return item.name.toLowerCase().includes(query) || item.email.toLowerCase().includes(query);
  });
});

let { page, totalPages, sublist: pageGuestAccounts } = usePaginatedList(filteredItems, PER_PAGE.value);

watch(searchQuery, () => {
  ({ page, totalPages, sublist: pageGuestAccounts } = usePaginatedList(filteredItems, PER_PAGE.value));
});
const isguestAccountsDataEmpty = computed(() => guestAccountsData.value.length === 0);
const handleDeleteGuestAccount = (guestAccountId) => {
  guestAccountIdToDelete.value = guestAccountId;
  showDeleteModal.value = true;
};

const closeDeleteModal = () => {
  showDeleteModal.value = false;
  guestAccountIdToDelete.value = null;
};

const accountModalTrigger = (val) => {
  showAccountForm.value = val;
  guestAccountToModify.value = null;
  isEditForm.value = false;
};

const removeCreditCard = () => {
  deleteAccountMutate(guestAccountIdToDelete.value, {
    onSuccess: () => notificationStore.notifySuccess("Guest account removed."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not remove that guest account."),
    onSettled: () => {
      closeDeleteModal();
    }
  });
};

const createGuestAccount = (accountDetails) => {
  createAccountMutate(accountDetails, {
    onSuccess: () => {
      notificationStore.notifySuccess("Your guest account has been created. An email has been sent to the account with further details.");
      accountModalTrigger(false);
      if (page.value !== 1) {
        page.value = 1;
      }
      rootElem.value.scrollIntoView(true);
    },
    onError: (error) => {
      const { response } = error;
      if (response.status != 409) {
        notificationStore.notifyError("Sorry, something went wrong and we could not create that guest account.");
        accountModalTrigger(false);
      }
    }
  });
};

const updateGuestAccount = (accountDetails) => {
  updateAccountMutate(accountDetails, {
    onSuccess: () => notificationStore.notifySuccess("Guest account updated."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not update that guest account."),
    onSettled: () => {
      accountModalTrigger(false);
      guestAccountToModify.value = null;
      isEditForm.value = false;
    }
  });
};

const updateClick = (accountId) => {
  guestAccountToModify.value = guestAccountsData.value.find((guestAccount) => guestAccount.id == accountId);
  isEditForm.value = true;
  showAccountForm.value = true;
};
</script>

<template>
  <div ref="rootElem" class="guestAccountList grid grid-cols-2 gap-y-f4">
    <FhHeading
      class="headerText mb-0"
      subheading="Give your clients or colleagues access to the full Four Hands catalog. For more information, see our Help Topics below. Please allow up to five minutes for Guest Account pricing updates to appear."
      >Guest Accounts</FhHeading
    >
    <div v-if="!isLoading" class="button addButton mt-f4 self-center text-right md:mt-0">
      <FhButton class="w-full md:w-auto" @click="accountModalTrigger(true)">Add Account</FhButton>
    </div>
    <div class="mainContent col-span-2">
      <div v-if="guestAccounts && guestAccounts.length > 0" class="relative max-w-full">
        <FhInput
          v-model="searchQuery"
          type="text"
          placeholder="Search by Guest Account Name or Email"
          :wrapper-attrs="{ class: 'gap-0 bg-white' }"
          class="border-gray-300 mt-5 mb-5 h-full pl-10 md:max-w-md"
        />
        <FhIcon name="Search" class="absolute top-[50%] left-3 -translate-y-1/2 transform text-2xl" style="color: grey" />
      </div>

      <FhGuestAccountListPresenter
        v-if="!isLoading"
        v-model="pageGuestAccounts"
        :has-error="isError"
        :is-empty="isguestAccountsDataEmpty"
        :markup="markup"
        @delete="handleDeleteGuestAccount"
        @create="createGuestAccount"
        @update="updateClick"
      ></FhGuestAccountListPresenter>
    </div>
  </div>
  <!-- PAGINATION -->
  <div v-if="totalPages > 1" class="mt-f4 md:w-full">
    <FhPaginationNav v-model:page="page" :total-items="guestAccounts.length" :per-page="PER_PAGE" @page-change="nextTick(() => scrollToTopOf(rootElem))" />
  </div>
  <FhLoader v-if="isLoading" class="guestAccount_loader col-span-2 my-f10" />
  <FhModal :data="{ heading: 'Confirm Guest Account Deletion' }" :is-open="showDeleteModal" @close="closeDeleteModal">
    <template #body>
      <p>This action will permanantely delete this subaccount and any of their associated account data, including shopping lists they've created.</p>
    </template>
    <template #footer="{ close }">
      <FhButton color="primary" :disabled="deleteMutationLoading" :is-loading="deleteMutationLoading" @click="removeCreditCard">Delete</FhButton>
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
  <FhGuestAccountForm
    v-if="showAccountForm"
    :is-open="showAccountForm"
    :is-creating="createMutationLoading || updateMutationLoading"
    :account-details="guestAccountToModify"
    :edit-mode="isEditForm"
    :error="createAccountError"
    :markup="markup"
    :show-price-markup="showPriceMarkup"
    :show-c-a-d-pricing="showCADPricing"
    @create="createGuestAccount"
    @update="updateGuestAccount"
    @update:is-open="accountModalTrigger"
  ></FhGuestAccountForm>
</template>

<style scoped type="pcss">
.headerText {
  grid-area: header-text;
}
.addButton {
  grid-area: add-button;
}

.mainContent {
  grid-area: main-content;
}
.guestAccountList {
  grid-template-areas:
    "header-text header-text"
    "main-content main-content"
    "add-button add-button";
}

@screen md {
  .guestAccountList {
    grid-template-areas:
      "header-text add-button"
      "main-content main-content";
  }
}
.guestAccount_loader {
  @apply h-14 w-14;
}
</style>
