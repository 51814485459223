<script setup>
import { computed, ref } from "vue";
import { getDataRefs } from "../util/vue";
import FhSection from "../includes/FhSection.vue";
import FhColumnModuleItem from "./FhColumnModule/FhColumnModuleItem.vue";
import FhColumnModuleItems from "./FhColumnModule/FhColumnModuleItems.vue";
import { useViewportSizes } from "../composables";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { isSm, isMd } = useViewportSizes();
const isMobile = computed(() => isSm.value || isMd.value);

const { items, skusWhitelist } = getDataRefs(props.data);
const totalItemCount = computed(() => items.value.length);

const filteredBase = computed(() => {
  const base = { ...props.style.base };
  if (totalItemCount.value > 0 && totalItemCount.value < 2) {
    delete base.background;
  }
  return base;
});

const activeSku = ref(null); // Used in the PDP context
function setActiveSku(sku) {
  activeSku.value = sku.skuNumber;
}
defineExpose({ setActiveSku });
</script>

<template>
  <slot name="banner" :set-active-sku="setActiveSku"></slot>
  <FhSection
    v-if="data && (activeSku === null || !skusWhitelist || skusWhitelist.length === 0 || Array.from(skusWhitelist).includes(activeSku))"
    aria-label="Column Module"
    :base="filteredBase"
    :class="[
      props.style.base?.background !== 'limestone' && props.style.base?.background !== null && props.style.base?.background !== '' ? 'text-white' : '',
      totalItemCount > 0 && totalItemCount < 2 && !isMobile ? `bg-${style.base.background}` : `widget--bg-${style.base.background}`
    ]"
  >
    <template v-if="totalItemCount > 0 && totalItemCount < 2">
      <fh-column-module-item v-for="item in items" :key="item.title" :item="item"></fh-column-module-item>
    </template>
    <template v-else>
      <fh-column-module-items :items="items" :total-item-count="totalItemCount"></fh-column-module-items>
    </template>
  </FhSection>
</template>

<style lang="pcss" scoped></style>
