<script setup>
import { computed, reactive, ref } from "vue";
import FhInput from "@/components/FhInput.vue";
import FhModal from "@/components/FhModal.vue";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";
import { useUpdateAccountPriceMarkup } from "@/api/accountApi";
import { useNotificationStore } from "@/stores/NotificationStore";

const props = defineProps({
  markupValue: {
    type: Number,
    default: 0
  }
});

const emits = defineEmits(["update"]);
const isSaveButtonDisabled = ref(false);
const formData = reactive({ markup: props.markupValue });
const modal = ref(null);
const notificationStore = useNotificationStore();
const { isLoading: updatingPriceModifer, mutate: updatePriceModiferMutation } = useUpdateAccountPriceMarkup();
const isMaxMarkupError = ref(false);
const isNegativeError = ref(false);

const grandfatheredUserCurrentMarkup = computed(() => {
  const value = props.markupValue;
  return Math.round(value * 2) / 2 !== value;
});

function updateSentence() {
  const value = parseFloat(formData.markup);
  if (grandfatheredUserCurrentMarkup.value && value === props.markupValue) {
    isSaveButtonDisabled.value = true;
    isMaxMarkupError.value = false;
    isNegativeError.value = false;
    return `We now only allow markups in 0.5% increments (e.g., 0.5%, 1.0%, 1.5%). Your current markup of ${value}% is grandfathered in, but if you change it, <strong>you will not be able to revert to this exact value.</strong>
    <div class="mt-f2">
    After the ${value}% markup is applied, a $1000 product will become $${(1000 + (value / 100) * 1000).toFixed(2)}.
    </div>
    `;
  } else if (Math.round(value * 2) / 2 !== value && !isNaN(value)) {
    isSaveButtonDisabled.value = true;
    isMaxMarkupError.value = true;
    isNegativeError.value = false;
    return `Markups must be in increments of 0.5% (e.g., 0.5%, 1.0%, 1.5%).`;
  } else if (value !== null && value < 0) {
    isSaveButtonDisabled.value = true;
    isMaxMarkupError.value = false;
    isNegativeError.value = true;
    return `Please enter a value of 0 or greater.`;
  } else if (value === 0) {
    isSaveButtonDisabled.value = false;
    isMaxMarkupError.value = false;
    isNegativeError.value = false;
    return `Enter a value greater than 0% to apply a markup.`;
  } else if (!isNaN(value) && value > 0 && value <= 500.0) {
    const roundedValue = parseFloat(value.toFixed(2));
    formData.markup = roundedValue;
    isSaveButtonDisabled.value = false;
    isMaxMarkupError.value = false;
    isNegativeError.value = false;
    return `After the ${value}% markup is applied, a $1000 product will become $${(1000 + (value / 100) * 1000).toFixed(2)}.`;
  } else if (value > 500.0) {
    isSaveButtonDisabled.value = true;
    isMaxMarkupError.value = true;
    isNegativeError.value = false;
    return "Maximum allowed markup is 500.00%";
  } else {
    isSaveButtonDisabled.value = false;
    isMaxMarkupError.value = false;
    isNegativeError.value = false;
    return "Enter a value greater than 0% to apply a markup.";
  }
}

const triggerUpdatePriceModifer = () => {
  formData.markup = formData.markup == "" ? 0 : formData.markup;
  updatePriceModiferMutation(formData, {
    onSuccess: (updatePriceModiferResponse) => {
      notificationStore.notifySuccess("Your price markup has been updated.");
      emits("update", updatePriceModiferResponse.markup);
      modal.value.close();
    },
    onError: () => {
      notificationStore.notifySuccess("Sorry, something went wrong and we could not update your price markup.");
    }
  });
};

const reset = () => {
  formData.markup = props.markupValue;
};
</script>

<template>
  <FhModal ref="modal" :data="{ heading: 'Update Price Markup' }" @close="reset">
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <form>
        <fieldset class="">
          <div class="flex flex-col gap-f2">
            <div class="flex w-full md:w-1/2">
              <FhInput
                v-model="formData.markup"
                :class="{ 'border-brand-red focus:border-brand-red': isMaxMarkupError || isNegativeError }"
                type="number"
                step="0.5"
                min="0"
                max="500.00"
                :max-decimal-places="1"
                @change="updateSentence"
              />
              <span><FhIcon name="IconPercentage" /></span>
            </div>

            <div class="sentence-container" :class="{ 'text-brand-red': isMaxMarkupError || isNegativeError }" v-html="updateSentence()"></div>
            <ul class="list-[square] p-4 grid gap-y-2">
              <li class="mx-4">To remove the markup, enter 0 or leave the field blank.</li>
              <li class="mx-4">Updates may take up to 5 minutes to reflect on Guest Accounts and Shopping Lists.</li>
            </ul>
            <div>
              Do you typically calculate markup using a multiplier? Here are some examples of common multipliers converted to markup percentages:<br /><br />
              <div class="grid grid-cols-2 border border-neutral-30 mx-f4 mt-f3">
                <div class="font-bold f-sm-base p-f2.5 border-b border-neutral-30">Multiplier</div>
                <div class="font-bold f-sm-base p-f2.5 border-b border-neutral-30">Markup %</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">1.8x</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">80%</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">2x</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">100%</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">2.1x</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">110%</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">2.5x</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">150%</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">3x</div>
                <div class="text-neutral-50 border-b border-neutral-30 px-f2.5 h-[44px] content-center">200%</div>
                <div class="text-neutral-50 px-f2.5 h-[44px] content-center">3.2x</div>
                <div class="text-neutral-50 px-f2.5 h-[44px] content-center">220%</div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </template>
    <template #footer="{ close }">
      <FhButton
        type="submit"
        color="primary"
        :is-loading="updatingPriceModifer"
        :disabled="updatingPriceModifer || isSaveButtonDisabled"
        @click="triggerUpdatePriceModifer"
        >{{ props.editMode ? "Update" : "Save" }}</FhButton
      >
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
</template>
