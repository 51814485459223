<script setup>
import { computed, ref, inject } from "vue";
import FhIcon from "@/components/FhIcon.vue";
import FhImage from "@/components/FhImage.vue";
import FhProductTags from "@/components/FhProductTags.vue";
import formatPrice from "@/util/formatPrice.js";

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  }
});
const shouldHidePrice = inject("shouldHidePrice");
const productDetails = ref({ ...props.product, ...props.product.additionalFields });
const productName = productDetails.value.ec_name;
const showEyebrowText = productDetails.value.collaboration === "Amber Lewis x Four Hands";

const hasOptionsOrStyles = computed(() => productDetails.value?.children?.length > 0);

const productSwatches = computed(() => {
  return (
    productDetails.value?.children?.filter((childProduct) => {
      const itemType = childProduct.additionalFields?.itemtype || "";
      return itemType.toLowerCase().includes("swatch");
    }) || []
  );
});

// Check if there are any dropdown options
const hasDropdownOptions = computed(() => {
  return productDetails.value?.children?.some((childProduct) => {
    const itemType = childProduct.additionalFields?.itemtype || "";
    return itemType.toLowerCase().includes("drop down");
  });
});

// This will pull from the effective price calculation of modifer and price we are defaulting to ec_price in case the calculation fails
const productPrice = productDetails.value.ec_effective_price || productDetails.value.ec_price;

// Add a computed property to determine if we're dealing with a price range
const isPriceRange = computed(() => {
  if (productDetails.value?.children?.length > 0) {
    const prices = productDetails.value.children
      .map((child) => Number(child.additionalFields.ec_effective_price) || Number(child.additionalFields.ec_price))
      .filter((price) => !isNaN(price) && price > 0);

    if (prices.length > 1) {
      const min = Math.min(...prices);
      const max = Math.max(...prices);
      return min !== max;
    }
  }
  return false;
});

// Use formatPrice utility instead of manual formatting
const formattedPrice = computed(() => {
  if (isPriceRange.value) {
    const prices = productDetails.value.children
      .map((child) => Number(child.additionalFields.ec_effective_price) || Number(child.additionalFields.ec_price))
      .filter((price) => !isNaN(price) && price > 0);

    if (prices.length > 0) {
      const min = Math.min(...prices);
      const max = Math.max(...prices);
      return `${formatPrice(min)} - ${formatPrice(max)}`;
    }
  }

  return productPrice ? formatPrice(Number(productPrice)) : "$0";
});

const isDiscounted = computed(() => !!productDetails.value.additionalFields.isdiscounted && props.isAdmin === true && props.isCustomerGuest === false);

const ogPriceForDiscount = computed(() => formatPrice(Number(productDetails.value.ec_price)));

const productTags = computed(() => {
  const productTags = JSON.parse(productDetails.value?.tags) || [];
  if (
    (productTags.length > 1 && productTags.at(0).includes("performance") && groupHasPerformanceOptions.value) ||
    (productTags.length == 0 && groupHasPerformanceOptions.value)
  ) {
    return ["Performance Options"];
  } else if (productTags.length == 0) {
    return [];
  }

  return [productTags.at(0)];
});

const groupHasPerformanceOptions = computed(() => {
  return productDetails.value?.children.some((childProduct) => childProduct.additionalFields?.hasperformancefabric == "true");
});
</script>
<template>
  <div>
    <div class="flex gap-f3">
      <div class="max-w-[172px] relative">
        <FhProductTags v-if="productTags.length > 0" :tags="productTags" class="product-tags" />
        <FhImage :src="productDetails.imageprimary" :alt="`${productName} Product Image`" border square class="w-full hover:border-black" />
      </div>
      <div class="self-center flex flex-col gap-f1 font-sans">
        <div>
          <div v-if="showEyebrowText" class="text-neutral-50 text-f-2xs-xs">Amber Lewis x Four Hands</div>
          <h1 class="capitalize text-lg text-black">{{ productName }}</h1>
        </div>
        <!-- Price -->
        <div v-if="!shouldHidePrice" class="flex mb-f1 whitespace-nowrap text-f-xs-sm">
          <div v-if="isDiscounted && !isPriceRange" class="mr-2 line-through">{{ ogPriceForDiscount }}</div>
          <span :class="{ 'text-brand-red': isDiscounted }">{{ formattedPrice }}</span>
        </div>
        <div v-if="hasOptionsOrStyles" class="text-neutral-50 text-f-xs-sm">
          <span v-if="hasDropdownOptions">More Options</span>
          <span v-else-if="productSwatches.length > 0" class="md:hidden">{{ productSwatches.length }} styles</span>
        </div>
        <div v-if="productDetails.ec_in_stock" class="text-body-xs flex items-center h-5 gap-1">
          <span class="mr-px">In Stock</span>
          <FhIcon name="LightningBolt" class="relative -top-px" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.product-tags {
  @apply hidden absolute pt-f2 pl-f2 lg:flex flex-col gap-2;
}
.product-tags :deep(span) {
  align-self: flex-start;
  text-transform: capitalize;
}
</style>
