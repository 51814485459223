<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
      <path
        id="Vector"
        d="M9 15.75V14.25H14.25V3.75H9V2.25H14.25C14.6625 2.25 15.0156 2.39687 15.3094 2.69063C15.6031 2.98438 15.75 3.3375 15.75 3.75V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H9ZM7.5 12.75L6.46875 11.6625L8.38125 9.75H2.25V8.25H8.38125L6.46875 6.3375L7.5 5.25L11.25 9L7.5 12.75Z"
        fill="black"
      />
    </g>
  </svg>
</template>
