// FilterConfiguration constants (Coveo)

const defaultFilterOrderConfiguration = [
  {
    filters: [
      "productavailability",
      "ec_category",
      "features",
      "functionality",
      "color",
      "artcolor",
      "skusize",
      "tableshape",
      "collaboration",
      "materialtype", //bedmaterial
      "upholsterymaterial",
      "width",
      "height",
      "seatingcapacity",
      "wallpapertype",
      "medium",
      "subject",
      "orientation",
      "framecolor",
      "ec_effective_price",
      "status"
    ]
  }
];

// URL-specific filter configurations
const urlSpecificConfigurations = {
  // Example for a specific URL path. TODO: I will add a real usecase when I find one
  "/shop/home-decor/wallpaper": [
    {
      filters: [
        "productavailability",
        "ec_category",
        "features",
        "wallpapertype",
        "functionality",
        "skusize",
        "color",
        "tableshape",
        "collaboration",
        "materialtype", //bedmaterial
        "upholsterymaterial",
        "width",
        "height",
        "seatingcapacity",
        "medium",
        "subject",
        "orientation",
        "framecolor",
        "ec_effective_price",
        "status"
      ]
    }
  ]
};

const getFilterOrderConfiguration = () => {
  const currentPath = window.location.pathname;

  const matchingConfig = urlSpecificConfigurations[currentPath];
  if (matchingConfig) {
    return matchingConfig;
  }

  // Return the default configuration if no match
  return defaultFilterOrderConfiguration;
};

const newOrder = (facets = []) => {
  const filterOrderConfiguration = getFilterOrderConfiguration();

  const allFilters = filterOrderConfiguration.flatMap((item) => item.filters);

  return facets.sort((a, b) => {
    const indexA = allFilters.indexOf(a.state.facetId);
    const indexB = allFilters.indexOf(b.state.facetId);

    // If both facets are found in the configuration, sort by their position
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only one facet is found, prioritize it
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // If neither facet is found, maintain original order
    return 0;
  });
};

export default function useCoveoSortedFacets() {
  return {
    filterOrderConfiguration: getFilterOrderConfiguration(),
    newOrder
  };
}
