<script setup>
import { watch, ref, computed } from "vue";

const props = defineProps({
  triggerAnimate: {
    type: Boolean,
    default: false,
    required: true
  },
  speed: {
    type: Number,
    default: 0.2
  },
  startPosition: {
    type: String,
    default: "down",
    validator: (value) => ["up", "down"].includes(value)
  }
});

const animateAAA = ref(null);
const animateBBB = ref(null);
const duration = computed(() => props.speed);
const arrowUp = computed(() => "M6.99 16.41L5.51001 15L12.5002 8.29297L19.49 15L18.01 16.41L12.5 11.12L6.99 16.41Z");
const arrowDown = computed(() => "M5.51367 9.70718L6.98681 8.29297L12.5002 13.5859L18.0137 8.29297L19.4868 9.70718L12.5002 16.4143L5.51367 9.70718Z");

// Trigger animation based on triggerAnimate prop
const triggerAnimation = (state) => {
  if (state) {
    animateAAA.value?.beginElement();
  } else {
    animateBBB.value?.beginElement();
  }
};

// Watch for changes to triggerAnimate and update animations
watch(
  () => props.triggerAnimate,
  (newVal) => {
    triggerAnimation(newVal);
  },
  { immediate: true }
);
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" :d="startPosition === 'up' ? arrowUp : arrowDown" fill="black">
      <animate ref="animateAAA" begin="indefinite" attributeName="d" fill="freeze" :dur="`${duration}s`" :to="startPosition === 'up' ? arrowDown : arrowUp" />
      <animate ref="animateBBB" begin="indefinite" attributeName="d" fill="freeze" :dur="`${duration}s`" :to="startPosition === 'up' ? arrowUp : arrowDown" />
    </path>
  </svg>
</template>
