import {
  buildSearch,
  buildSearchBox,
  buildContext,
  buildRecentQueriesList,
  buildInstantProducts,
  buildProductListing,
  searchSerializer
} from "@coveo/headless/commerce";
import { headlessCommerceEngine } from "@/coveo/engine";

let lastQuery = "";
const DEFAULT_PAGE_SIZE = 27;

// reset the lastQuery
export const resetLastQuery = () => {
  lastQuery = "";
};

// Add this constant and export it
export const SUGGESTIONS_LIMIT = 6;

// Add suggestion types enum
export const SuggestionType = {
  SEARCH: "search",
  RESULTS: "results"
};

const highlightOptions = {
  correctionDelimiters: { open: "<em>", close: "</em>" },
  exactMatchDelimiters: { open: "<strong>", close: "</strong>" }
};

// The buildSearchBox function creates an instance of the SearchBox controller
export const searchBox = buildSearchBox(headlessCommerceEngine, {
  options: {
    id: "search-box",
    highlightOptions
  }
});

export const instantProducts = buildInstantProducts(headlessCommerceEngine, {
  options: {
    searchBoxId: "search-box",
    cacheTimeout: 0
  }
});

export const recentQueries = buildRecentQueriesList(headlessCommerceEngine, {
  initialState: {
    queries: []
  },
  options: {
    maxLength: 9,
    clearFilters: false
  }
});

export const popularSearchesController = buildSearchBox(headlessCommerceEngine, {
  options: {
    id: "popular-searches"
  }
});

export const productListing = buildProductListing(headlessCommerceEngine);
export const context = buildContext(headlessCommerceEngine);

export const productListingFacetSubController = productListing.facetGenerator();
export const paginationSubController = productListing.pagination({
  initialState: {
    pageSize: DEFAULT_PAGE_SIZE
  }
});

export const updateSearchText = (query) => {
  // Only update if the query has changed to prevent duplicate API calls
  if (lastQuery !== query) {
    lastQuery = query;
    searchBox.updateText(query);
    instantProducts.updateQuery(query);
  }
};

export const setSearchQuery = (query) => {
  searchBox.value.updateText(query);
};

// Single search controller with all needed features
export const searchController = buildSearch(headlessCommerceEngine);

// Export only one version of each sub-controller
export const didYouMeanController = searchController.didYouMean();
export const facetsController = searchController.facetGenerator();
export const paginationController = searchController.pagination();
export const sortController = searchController.sort();
export const breadcrumbsController = searchController.breadcrumbManager();

// Export serialize/deserialize helpers from Coveo
export const { serialize, deserialize } = searchSerializer;

// Simplify URL parameter handling for more consistent behavior
export const syncUrlParameters = (controller, params = {}) => {
  try {
    // Make a copy to avoid modifying original params
    const syncParams = { ...params };

    // Use Coveo's synchronize method
    controller.synchronize(syncParams);
    return true;
  } catch (error) {
    console.error("Error synchronizing parameters:", error);
    return false;
  }
};

// Helper to safely get URL parameters with error handling
export const getUrlParameters = () => {
  try {
    const params = window.location.search.slice(1);
    return params ? deserialize(params) : {};
  } catch (error) {
    console.error("Failed to parse URL parameters:", error);
    return {};
  }
};

// Initialize parameter managers with URL parameters (Coveo's recommended approach)
const initialParameters = getUrlParameters();

// Initialize parameter manager for search
export const parameterController = searchController.parameterManager({
  initialState: { parameters: initialParameters }
});

// Initialize parameter manager for product listing
export const plpParameterController = productListing.parameterManager({
  initialState: { parameters: initialParameters }
});

// Track if user has interacted with sort controls - use let so it can be modified
export let hasSortBeenInteractedWith = false;

// Function to mark sort as interacted with - simplified with no unnecessary logging
export const markSortAsInteractedWith = () => {
  hasSortBeenInteractedWith = true;
};

// Function to reset sort interaction state - simplified with no unnecessary logging
export const resetSortInteraction = () => {
  hasSortBeenInteractedWith = false;
};

// Filter parameters for URL display - simplified version
export const filterDisplayParameters = (params) => {
  if (!params) return {};

  const displayParams = { ...params };

  // Remove perPage parameter regardless of value
  if ("perPage" in displayParams) delete displayParams.perPage;

  // Handle sortCriteria parameter - simplified logic
  if (displayParams.sortCriteria) {
    // Always remove sortCriteria if user hasn't interacted with sort control
    if (!hasSortBeenInteractedWith) {
      delete displayParams.sortCriteria;
    }
    // Remove 'relevance' sorting (using any format) if user has interacted
    else if (
      displayParams.sortCriteria === "relevance" ||
      (typeof displayParams.sortCriteria === "string" && displayParams.sortCriteria.toLowerCase().trim() === "relevance") ||
      (typeof displayParams.sortCriteria === "object" && displayParams.sortCriteria.by === "relevance")
    ) {
      delete displayParams.sortCriteria;
    }
  }

  return displayParams;
};

export const updateFilteredUrl = (params) => {
  try {
    // Don't update URL during initial page load
    if (document.readyState !== "complete" || (window._pageLoadTime && Date.now() - window._pageLoadTime < 1000)) {
      return;
    }

    // Filter parameters for URL display
    const displayParams = filterDisplayParameters(params);

    // Update the URL using Coveo's serialize helper
    const serializedParams = serialize(displayParams);
    const currentUrl = window.location.pathname + (window.location.search || "");
    const newUrl = serializedParams ? `${window.location.pathname}?${serializedParams}` : window.location.pathname;

    // Only update if the URL actually changed
    if (newUrl !== currentUrl) {
      window.history.replaceState(params, "", newUrl);
    }
  } catch (error) {
    console.error("Error updating URL:", error);
  }
};

// Combined function to apply parameters and execute search
export const applyParametersAndSearch = (query, customParams = {}) => {
  try {
    // Merge parameters
    const params = { ...initialParameters, ...customParams };

    // Apply parameters
    syncUrlParameters(parameterController, params);

    // Update search text if provided
    if (query?.trim()) {
      searchBox.updateText(query);
    }
  } catch (error) {
    console.error("Search execution error:", error);
    return null;
  }
};

// Helper to apply parameters and fetch product listing
export const applyParametersAndFetchProducts = (customParams = {}) => {
  try {
    // Get parameters - either from the argument or from URL
    const params = Object.keys(customParams).length > 0 ? customParams : getUrlParameters();

    // Apply parameters to the controller BEFORE executing request
    syncUrlParameters(plpParameterController, params);
  } catch (error) {
    console.error("Product listing error:", error);
    return null;
  }
};

// Helper to execute search with current query
export const executeSearch = (query) => {
  if (!query?.trim()) return;

  // Use submit() to execute the search with the current query
  searchBox.submit();
  return searchController.executeFirstSearch();
};

export const sortBySubController = productListing.sort();
