<script setup>
import { computed, onMounted, nextTick } from "vue";
import { SHIPPING_OPTIONS, FREIGHT_SERVICE_LEVEL } from "@/constants/checkout";
import { useViewportSizes } from "@/composables";
import formatPrice from "@/util/formatPrice";
import FhButton from "@/components/FhButton.vue";
import FhCartItem from "@/components/FhCartItem.vue";
import FhCheckoutShippingDetailsSummary from "@/components/FhCheckoutShippingDetailsSummary.vue";
import FhShippingAddress from "@/components/FhShippingAddress.vue";
import FhPaymentCard from "@/components/Ecomm/FhPaymentCard/FhPaymentCard.vue";
import { event_Purchase } from "@/util/googleAnalytics";

const props = defineProps({
  orderNumber: {
    type: String,
    required: true
  },
  orderDate: {
    type: String,
    required: true
  },
  email: {
    type: String,
    required: true
  },
  totalItemsPrice: {
    type: Number,
    required: true
  },
  totalShippingPrice: {
    type: Number,
    required: true
  },
  /**
   * If `true`, the `totalShippingPrice` prop will be ignored and the shipping price will be displayed as "to be quoted"
   */
  shippingPriceToBeQuoted: {
    type: Boolean,
    default: false
  },
  totalTaxPrice: {
    type: Number,
    required: true
  },
  totalPrice: {
    type: Number,
    required: true
  },
  items: {
    type: Array,
    required: true
  },
  shippingOption: {
    type: String,
    default: null,
    validator: (val) => Object.values(SHIPPING_OPTIONS).includes(val)
  },
  shippingAddress: {
    type: Object,
    default: null
  },
  freightServiceLevel: {
    type: String,
    default: null,
    validator: (val) => Object.values(FREIGHT_SERVICE_LEVEL).includes(val)
  },
  carrierNotes: {
    type: String,
    default: null
  },
  carrierName: {
    type: String,
    default: null
  },
  carrierAccountNumber: {
    type: String,
    default: null
  },
  pickUpContactName: {
    type: String,
    default: null
  },
  pickUpContactPhone: {
    type: String,
    default: null
  },
  holdDate: {
    type: String,
    default: null
  },
  hasTerms: {
    type: Boolean,
    default: false
  },
  paymentCard: {
    type: Object,
    default: null
  },
  poNumber: {
    type: String,
    default: null
  }
});

const { isSm } = useViewportSizes();

const orderDateFormatted = computed(() => {
  return new Date(props.orderDate)?.toLocaleDateString(navigator.language || "en-US", { dateStyle: "long" });
});

const showShippingAddress = computed(() => props.shippingAddress && props.shippingOption !== SHIPPING_OPTIONS.PICK_UP);

function print() {
  window.print();
}

onMounted(() => {
  nextTick(() => {
    const sendPurchaseEvent = () => {
      if (window.relay) {
        event_Purchase({
          orderNumber: props.orderNumber,
          totalPrice: props.totalPrice,
          tax: props.totalTaxPrice,
          shippingPrice: props.totalShippingPrice,
          products: props.items
        });
      } else {
        setTimeout(sendPurchaseEvent, 50);
      }
    };
    sendPurchaseEvent();
  });
});
</script>

<template>
  <div>
    <div class="mb-f6 flex flex-col gap-f4 md:flex-row md:items-center md:gap-f6">
      <div>
        <div class="text-f-xs-base text-neutral-50">Order Date</div>
        <div class="text-f-base-xl">{{ orderDateFormatted }}</div>
      </div>
      <div>
        <div class="text-f-xs-base text-neutral-50">Email Address</div>
        <div class="text-f-base-xl">{{ props.email }}</div>
      </div>
      <FhButton v-if="!isSm" class="md:ml-auto" @click="print">Print Order</FhButton>
    </div>
    <div class="mb-f6 grid gap-px bg-neutral-30 p-px" :class="[showShippingAddress ? 'md:grid-cols-3' : 'md:grid-cols-2']">
      <div v-if="showShippingAddress" class="bg-white p-5 md:p-6">
        <div class="mb-2">Delivery Address</div>
        <FhShippingAddress v-bind="props.shippingAddress" simple-display class="text-f-xs-base text-neutral-50" />
      </div>
      <div class="bg-white p-5 md:p-6">
        <div class="mb-2">Delivery Information</div>
        <FhCheckoutShippingDetailsSummary v-bind="$props" class="text-f-xs-base text-neutral-50" />
      </div>
      <div class="bg-white p-5 md:p-6">
        <div class="mb-2">Payment Method</div>
        <div v-if="props.hasTerms" class="text-f-xs-base text-neutral-50">Payment Terms</div>
        <FhPaymentCard v-else v-bind="props.paymentCard" :show-remove-link="false" />
      </div>
    </div>
    <div class="mb-f6">
      <FhCartItem v-for="item in props.items" :key="item.sku.skuNumber" v-bind="item" view-only class="border-t-0" />
    </div>
    <div class="flex flex-col gap-f4 border-b border-neutral-30 pb-f4">
      <div class="flex justify-between">
        <div class="text-f-base-2xl">Subtotal</div>
        <div class="text-f-base-xl">{{ formatPrice(props.totalItemsPrice) }}</div>
      </div>
      <div class="flex justify-between">
        <div class="text-f-base-2xl">Shipping</div>
        <div v-if="props.shippingPriceToBeQuoted" class="text-f-base-xl">{{ isSm ? "To be quoted" : "Quote provided at time of shipment" }}</div>
        <div v-else class="text-f-base-xl">{{ formatPrice(props.totalShippingPrice) }}</div>
      </div>
      <div class="flex justify-between">
        <div class="text-f-base-2xl">Taxes</div>
        <div class="text-f-base-xl">{{ formatPrice(props.totalTaxPrice) }}</div>
      </div>
      <div v-if="props.poNumber" class="flex justify-between">
        <div class="text-f-base-2xl">PO Number</div>
        <div class="text-f-base-xl">{{ props.poNumber }}</div>
      </div>
      <div class="flex justify-between">
        <div class="text-f-base-2xl">Total</div>
        <div class="text-f-xl-4xl">{{ formatPrice(props.totalPrice) }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.paymentCard) {
  @apply flex-row items-start;
}
:deep(.paymentCard__icon) {
  @apply w-16;
}
:deep(.paymentCard__name) {
  @apply mb-0 text-f-xs-base text-neutral-50;
}
:deep(.paymentCard__details) {
  @apply flex-col gap-0 text-f-xs-base text-neutral-50;
}
:deep(.paymentCard__detailsDivider) {
  @apply hidden;
}
</style>
