<script setup>
import { ref, onMounted, onUnmounted, computed, provide } from "vue";
import {
  searchController,
  didYouMeanController,
  facetsController,
  popularSearchesController,
  searchBox as searchBoxController,
  context,
  sortController,
  parameterController,
  updateFilteredUrl,
  applyParametersAndSearch,
  getUrlParameters,
  resetSortInteraction
} from "@/coveo/controllers";
import useCoveoSortedFacets from "@/composables/useCoveoSortedFacets";
import FhCoveoProductGrid from "@/components/FhCoveoProductGrid.vue";
import FhCoveoFacet from "@/components/FhCoveo/FhCoveoFacet.vue";
import FhQuickLinks from "@/components/FhQuickLinks.vue";
import FhPopularSearchChips from "@/components/FhPopularSearchChips.vue";
import FhLoader from "@/components/FhLoader.vue";
import FhFade from "@/components/FhFade.vue";
import FhButton from "@/components/FhButton.vue";
import { useInfiniteScroll } from "@/composables/useInfiniteScroll";
import { useHeaderAnimation } from "@/composables/useHeaderAnimation";

const { newOrder } = useCoveoSortedFacets();
// Core state management using Coveo's state structure
const searchState = ref(searchController.state);
const didYouMeanState = ref(didYouMeanController.state);

const productToSearch = ref(null);

// Suggestions and corrections state
const suggestions = ref([]);
const isProcessing = ref(false);
const isContentVisible = ref(false);

// Popular searches state
const popularSuggestions = ref();

// Facet state management
const facetsData = ref(facetsController.facets);

const hasInitialFacets = ref(false);

const products = computed(() => searchState.value.products || []);
const isLoading = computed(() => searchState.value.isLoading);
const hasProducts = computed(() => products.value.length > 0);

// Pagination / Infinite Scroll
const paginationSubController = searchController.pagination();
const paginationState = ref(paginationSubController.state);
const totalEntries = computed(() => paginationState.value.totalEntries);
const scrollThresholdMeet = computed(() => !isLoading.value && products.value?.length % 108 === 0);
const scrollEnd = computed(() => !isLoading.value && products.value.length >= totalEntries.value);

const observerTarget = ref(null);

const { loadingMore, fetchMoreProduct } = useInfiniteScroll(
  observerTarget,
  paginationSubController.fetchMoreProducts,
  isLoading,
  scrollEnd,
  scrollThresholdMeet
);

// Query correction states
const hasQueryCorrection = computed(() => didYouMeanState.value.hasQueryCorrection);
const wasCorrected = computed(() => didYouMeanState.value.wasCorrected);
const correctedQuery = computed(() => didYouMeanState.value.wasCorrectedTo);

// Fix suggestions text computation
const suggestionsText = computed(() => {
  if (!suggestions.value?.length) return "";
  const count = suggestions.value.length;
  return `Showing ${count} suggestion${count !== 1 ? "s" : ""}`;
});

const showNoResults = computed(() => !isLoading.value && !hasProducts.value && !isProcessing.value);

// Display logic for corrections
const showSearchInstead = computed(() => didYouMeanState.value.wasAutomaticallyCorrected || didYouMeanState.value.wasCorrected);

const shouldShowFacets = computed(() => {
  return hasProducts.value && hasInitialFacets.value && Object.keys(facetsData.value).length > 0;
});

const headerText = computed(() => {
  // Autocorrected term -> capitalized, no quotes
  if (didYouMeanState.value.wasAutomaticallyCorrected || didYouMeanState.value.wasCorrected) {
    return {
      text: didYouMeanState.value.wasCorrectedTo,
      shouldQuote: false,
      shouldCapitalize: true
    };
  }

  // No results -> original case, with quotes
  if (showNoResults.value) {
    return {
      text: productToSearch.value,
      shouldQuote: true,
      shouldCapitalize: false
    };
  }

  // Has results, normal search -> capitalized, no quotes
  return {
    text: productToSearch.value,
    shouldQuote: false,
    shouldCapitalize: true
  };
});

const showDoYouMean = computed(() => hasQueryCorrection.value && !wasCorrected.value && showNoResults.value);

const props = defineProps({
  url: {
    type: String,
    required: true
  },
  pageName: {
    type: String,
    required: true
  },
  searchQuery: {
    type: String,
    default: ""
  },
  categories: {
    type: Array,
    default: () => []
  },
  categoryStatus: {
    type: String,
    default: "none"
  },
  priceMarkup: {
    type: Object,
    default: () => {}
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  shouldHidePrice: {
    type: Boolean,
    default: false
  }
});
provide("shouldHidePrice", props.shouldHidePrice);
const unsubscribeSearch = ref(null);
const unsubscribeDidYouMean = ref(null);

const initializeSearch = () => {
  // Reset sort interaction state when initializing search
  resetSortInteraction();
  isContentVisible.value = false;
  isProcessing.value = true;
  hasInitialFacets.value = false;

  const searchParams = new URLSearchParams(window.location.search);
  productToSearch.value = searchParams.get("q");

  try {
    const constructUrl = () => {
      const locationManager = window?.location;
      // Always use just the pathname (without query params)
      if (locationManager.hostname === "localhost") {
        return `https://fourhands.com${locationManager.pathname}`;
      }
      // For non-localhost, use origin + pathname (without query params)
      return `${locationManager.origin}${locationManager.pathname}`;
    };

    context.setView({
      url: constructUrl(),
      referrer: document?.referrer,
      userType: props.isAdmin ? "admin" : props.isCustomerGuest ? "guest" : undefined
    });

    // Get all URL parameters
    const urlParams = getUrlParameters();
    // Set up subscriptions for state changes
    parameterController.subscribe(() => {
      updateFilteredUrl(parameterController.state.parameters);
    });

    // Store unsubscribe functions in refs
    unsubscribeSearch.value = searchController.subscribe(() => {
      searchState.value = searchController.state;
      products.value = searchState.value.products;
    });

    unsubscribeDidYouMean.value = didYouMeanController.subscribe(() => {
      didYouMeanState.value = didYouMeanController.state;
    });

    // Add facet subscription
    facetsController.subscribe(() => {
      facetsData.value = newOrder(facetsController.facets);
      hasInitialFacets.value = true;
    });

    // Add Popular Search subscription
    popularSearchesController.subscribe(() => {
      popularSuggestions.value = popularSearchesController.state.suggestions;
    });

    searchBoxController.updateText(productToSearch.value);

    // Add Pagination subscription
    paginationSubController.subscribe(() => {
      paginationState.value = paginationSubController.state;
    });

    // searchController.executeFirstSearch();
    // Update search box and execute first search after all initialization
    // searchBoxController.updateText(productToSearch.value);

    // Apply parameters and execute search with all URL parameters
    applyParametersAndSearch(productToSearch.value, urlParams);

    firstCallPopularSearches();
    // Use URL directly from props
    // Only handle corrections after initial search
    if (hasQueryCorrection.value) {
      didYouMeanController.applyCorrection();
    }
  } catch (error) {
    console.error("Search initialization error:", error);
  } finally {
    isContentVisible.value = true;
    isProcessing.value = false;
  }
};

const firstCallPopularSearches = () => {
  popularSearchesController.updateText("");
};

onUnmounted(() => {
  if (unsubscribeSearch.value) {
    unsubscribeSearch.value();
  }
  if (unsubscribeDidYouMean.value) {
    unsubscribeDidYouMean.value();
  }
});

onMounted(initializeSearch);

const isReady = computed(() => {
  return !isProcessing.value && !isLoading.value && isContentVisible.value;
});
const hasSuggestions = computed(() => searchBoxController.state.suggestions?.length > 0);
const filteredSuggestions = computed(() => searchBoxController.state.suggestions.filter((suggestion) => suggestion.rawValue !== headerText.value.text));

const shouldShowSuggestions = computed(
  () => !isLoading.value && hasProducts.value && hasSuggestions.value && !searchBoxController.state.isLoadingSuggestions && !showSearchInstead.value
);

const percantageComplete = computed(() => {
  if (products.value) {
    return Math.floor((products.value.length / totalEntries.value) * 100);
  }
  return 0;
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

// Facet ref for header animation
const facetBarRef = ref(null);
// Initialize header animation without destructuring unused variables
useHeaderAnimation(facetBarRef);
</script>

<template>
  <div class="results-page">
    <div class="results-container">
      <!-- Search Header - Only show when ready and has query -->
      <div v-if="productToSearch" class="py-f5 w-full">
        <div class="flex flex-col gap-f4 mb-f5">
          <!-- Search Header -->
          <div v-if="isReady" class="text-neutral-50 text-xl tb:text-3xl">
            <p>
              Search results for
              <span class="text-black" :class="{ capitalize: headerText.shouldCapitalize }">
                <template v-if="headerText.shouldQuote">"{{ headerText.text }}"</template>
                <template v-else>{{ headerText.text }}</template>
              </span>
            </p>
          </div>

          <!-- Related Searches -->
          <div v-if="shouldShowSuggestions" class="tb:h-7 tb:inline-flex">
            <div class="text-neutral-50 leading-normal tb:mr-4">Related Searches:</div>
            <FhFade
              class="related-searches items-start w-full tb:w-auto overflow-y-hidden snap-x snap-mandatory py-f2 tb:py-0 justify-start hide-scrollbar gap-4"
              fade-length="10"
              disable-above="728"
            >
              <div class="flex justify-start items-center gap-4">
                <div v-for="suggestion in filteredSuggestions" :key="suggestion.rawValue" class="snap-start">
                  <a
                    :href="`/search?q=${suggestion.rawValue}`"
                    class="text-center text-black text-base border-b border-b-black whitespace-nowrap capitalize tb:hover:text-neutral-50 tb:hover:border-neutral-50"
                  >
                    {{ suggestion.rawValue }}
                  </a>
                </div>
              </div>
            </FhFade>
          </div>

          <!-- Replace "search instead for" with "No matches for" when query was corrected -->
          <template v-if="showSearchInstead">
            <div class="justify-start items-start gap-1 flex h-7 text-neutral-50 leading-normal">
              No matches for <span class="text-black">"{{ productToSearch }}"</span>
            </div>
          </template>
        </div>

        <!-- Results Content -->
        <div class="results-content">
          <div ref="facetBarRef" class="facet-bar sticky top-0 z-10 flex flex-row gap-7 bg-white justify-between">
            <FhCoveoFacet
              v-if="shouldShowFacets && hasProducts"
              :is-loading="isLoading"
              :facets="facetsData"
              :total-amount="totalEntries"
              :sort-controller="sortController"
              @clear-all="facetsController.deselectAll()"
            />
            <div v-if="props.totalProductsCount > 0" class="ml-auto">{{ props.totalProductsCount }} items</div>
          </div>

          <!-- Product Results -->
          <template v-if="products?.length">
            <FhCoveoProductGrid
              :products="products"
              :is-admin="isAdmin"
              :price-markup="priceMarkup"
              :is-c-a-d-price="isCADPrice"
              :is-customer-guest="isCustomerGuest"
              :coveo-controller="searchController"
            />
          </template>

          <!-- No Results State -->
          <div v-else-if="showNoResults" class="w-full no-products">
            <div class="tb:inline-flex w-full gap-f8 tb:gap-y-f25 justify-between pt-f5">
              <div class="mb-f8 tb:mb-0">
                <div class="text-black text-4xl mt-f3 mb-8 tb:pr-f15 lg:pr-f25 dt:max-w-[984px]">
                  <p>Sorry, couldn’t find any results for that. Please try another search term.</p>
                </div>
                <div v-if="showDoYouMean" class="text-neutral-50 mt-4">
                  <span>Did you mean </span>
                  <a :href="`/coveo/search?q=${correctedQuery}`" class="text-black border-b border-b-black hover:text-neutral-50 hover:border-neutral-50">
                    "{{ correctedQuery }}"
                  </a>
                  <span>?</span>
                </div>
                <p v-if="suggestions?.value?.length > 0" class="text-neutral-50 mt-2">{{ suggestionsText }}</p>
                <div class="justify-start items-start inline-flex">
                  <div class="text-neutral-50">0 matches for "{{ productToSearch }}"</div>
                </div>
              </div>
              <FhQuickLinks />
            </div>

            <!-- Categories and Popular Searches -->
            <div class="mt-f8">
              <FhPopularSearchChips v-if="popularSuggestions?.length >= 6" :data="popularSuggestions" />
            </div>
            <div class="mt-f8">
              <slot name="categories"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="observerTarget" class="h-px absolute mb-0"></div>

    <!-- Loading State -->
    <div v-if="isLoading || loadingMore" class="flex justify-center items-center mt-f8">
      <FhLoader class="h-9 w-9" />
    </div>
    <div v-if="(scrollThresholdMeet || scrollEnd) && totalEntries > 0" class="flex flex-col justify-center items-center pb-7">
      <div class="mb-8 w-56">
        <span class="text-base font-medium text-neutral-50 block w-full text-center mb-2">Showing {{ products?.length }} of {{ totalEntries }}</span>
        <div class="mb-5 h-2 rounded-full bg-neutral-30">
          <div class="h-2 rounded-full bg-black" :style="{ width: `${percantageComplete}%` }"></div>
        </div>
      </div>
      <div class="flex items-center justify-center gap-3">
        <FhButton class="py-4 px-8" data-fh-ga="scroll-to-top" @click="scrollToTop">Back To Top</FhButton>
        <FhButton v-if="!scrollEnd" color="primary" class="py-4 px-8" data-fh-ga="load-more" :disabled="isLoading || loadingMore" @click="fetchMoreProduct"
          >View More</FhButton
        >
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.facet-bar:after {
  content: "";
  position: absolute;
  width: 100%;
  display: block;
  height: 1px;
  z-index: 0;
  @apply bg-neutral-30 bottom-0 left-0;
}

/* Header animation classes */
.animate-main-content-slide {
  animation: main-content-slide-down 0.3s ease-in-out forwards;
  top: var(--header-height, 0);
}

.animate-main-content-slide-leave {
  animation: main-content-slide-up 0.3s ease-in-out forwards;
  top: 0;
}

/* Header animation classes */
:global(header.show-header.animate-header-slide) {
  animation: header-slide-down 0.3s ease-in-out forwards;
  position: fixed;
  width: 100%;
  z-index: 50;
}

:global(header.show-header.animate-header-slide-leave) {
  animation: header-slide-up 0.35s ease-in-out forwards;
  position: fixed;
  width: 100%;
  z-index: 50;
}

@keyframes header-slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes header-slide-up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes main-content-slide-down {
  from {
    top: 0;
  }
  to {
    top: var(--header-height, 0);
  }
}

@keyframes main-content-slide-up {
  from {
    top: var(--header-height, 0);
  }
  to {
    top: 0;
  }
}
</style>
