<script setup>
import { ref, computed, watch, useSlots } from "vue";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import FhButton from "../components/FhButton.vue";
import FhIcon from "../components/FhIcon.vue";
import FhWysiwyg from "../includes/FhWysiwyg.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  wide: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

const emit = defineEmits(["update:isOpen", "open", "close", "afterEnter", "afterLeave"]);

const _isOpen = ref(props.isOpen);

watch(
  () => props.isOpen,
  (val) => (_isOpen.value = val)
);

watch(_isOpen, (val) => {
  emit("update:isOpen", val);
  emit(val ? "open" : "close");
});

const slots = useSlots();
const bodySlotName = computed(() => (slots.body ? "body" : "default"));
const hasFooter = computed(() => props.data.ctaText || slots.footer);

const window = ref(null);

function open() {
  _isOpen.value = true;
}

function close() {
  _isOpen.value = false;
}

defineExpose({ window, open, close });
</script>

<template>
  <slot name="activator" :open="open"></slot>
  <TransitionRoot appear :show="_isOpen" as="template" @after-enter="emit('afterEnter')" @after-leave="emit('afterLeave')">
    <Dialog as="div" class="relative z-body-modal" @close="close">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral-opacity-60" aria-hidden="true"></div>
      </TransitionChild>

      <div ref="window" class="fixed inset-0 flex items-center justify-center overflow-y-auto md:py-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            name="wrapper"
            class="my-auto mx-0 flex min-h-[var(--100vh)] w-full flex-col bg-white px-4 py-8 transition-all md:h-auto md:min-h-[20rem] md:px-10 md:pb-10 overflow-x-auto"
            :class="[props.wide ? 'md:max-w-screen-md' : 'md:max-w-[34rem]']"
          >
            <!-- CLOSE BUTTON -->
            <div class="mb-f4 flex justify-end">
              <FhButton size="small" variant="round" class="relative md:-right-2" aria-label="Close Modal" @click="close">
                <FhIcon name="XIcon" class="!text-2xl" />
              </FhButton>
            </div>
            <!-- TITLE -->
            <DialogTitle v-if="data.heading" as="h2" class="mb-f5 flex flex-col gap-f1">
              <span class="font-serif text-f-3xl-5xl">{{ data.heading }}</span>
              <div v-if="data.subheading">{{ data.subheading }}</div>
            </DialogTitle>
            <!-- BODY -->
            <div class="mb-f3 grow">
              <slot :name="bodySlotName">
                <FhWysiwyg v-if="data.copy" :content="data.copy" spacing />
              </slot>
            </div>
            <!-- FOOTER -->
            <div v-if="hasFooter" class="modal__footer md mt-f3 flex flex-col gap-f2 md:flex-row-reverse md:items-center md:justify-start">
              <slot name="footer" :close="close">
                <FhButton
                  color="primary"
                  :href="data.ctaURL"
                  :target="data.ctaTarget"
                  :aria-label="data.ctaAriaLabel"
                  :download="data.ctaDownload"
                  :title="data.ctaText"
                >
                  {{ data.ctaText }}
                </FhButton>
              </slot>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.modal__footer:deep :where(.btn--text, .btn--link) {
  @apply md:mx-f2;
}
</style>
